<template>
    <div class="card lg:px-9">
        <div style="height: 20%" class="border-2 border-dashed surface-border mb-2 p-2">
            <div>
                <span class="flex flex-column gap-2">
                    <label for="ticker">Identifier*</label>
                    <InputText id="ticker" placeholder="RUCAVE30 or RUCAVE30,RUCAVE31,RUCAVE32" v-model="ticker" />
                    <small id="ticker-help">*isbn, ticker, ticker_composite, ref_ticker (ex: RUCAVE30 or RUCAVE30,RUCAVE31,RUCAVE32)</small>
                </span>
            </div>
        </div>
        <div style="height: 20%" class="border-2 border-dashed surface-border mb-2 p-2">
            <div>
                <Button @click="download('ticker')" icon="pi pi-download" label="Download" severity="success" class="ml-2 flex-row" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'normalizedGenerateXLSX',
    data() {
        return {
            ticker: null
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
            downloadNormalizedXLSX: 'downloadNormalizedXLSX'
        }),
        download(type) {
            const payload = {
                type: type,
                options: this.ticker
            }
            
            this.downloadNormalizedXLSX(payload);
        }
    }
};
</script>