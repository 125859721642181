<template>
    <div>
        <div class="flex mt-3 w-12">
            <div class="flex flex-column gap-2 w-3">
                <label for="name">Name</label>
                <InputText id="name" :invalid="!supplier.name" v-model="supplier.name" placeholder="Name" />
            </div>

            <div class="flex flex-column gap-2 w-3 ml-2">
                <label for="pseudo">Pseudo (visible by users)</label>
                <InputText id="pseudo" :invalid="!supplier.pseudo" v-model="supplier.pseudo" placeholder="Alias" />
            </div>
        </div>

        <div class="flex mt-3 w-12">
            <div class="flex flex-column gap-2 w-6">
                <label for="address">Address</label>
                <InputText id="address" :invalid="!supplier.address.address" v-model="supplier.address.address" placeholder="Address" />
            </div>

            <div class="flex flex-column gap-2 w-2 ml-2">
                <label for="zip">Postal code</label>
                <InputText id="zip" :invalid="!supplier.address.zip" v-model="supplier.address.zip" placeholder="postal code" />
            </div>

            <div class="flex flex-column gap-2 w-2 ml-2">
                <label for="city">City</label>
                <InputText id="city" :invalid="!supplier.address.city" v-model="supplier.address.city" placeholder="city" />
            </div>

            <div class="flex flex-column gap-2 w-2 ml-2">
                <label for="region">Region</label>
                <InputText id="region" v-model="supplier.address.region" placeholder="region" />
            </div>
        </div>

        <div class="flex mt-3 w-12">
            <Dropdown v-model="supplier.address.country" :invalid="!supplier.address.country" editable optionLabel="nom_en_gb" :options="getCountry" placeholder="Select a country">
                <template #option="slotProps">
                    <div class="flex items-center">
                        <div>{{ slotProps.option.nom_en_gb }} ({{ slotProps.option.alpha2 }}) </div>
                    </div>
                </template>
            </Dropdown>
        </div>

        <Button
            label="Create supplier"
            severity="success"
            class="mt-4"
            @click="create()"
            :disabled="!supplier.name || !supplier.pseudo || !supplier.address.address || !supplier.address.city || !supplier.address.zip || !supplier.address.country"
        />

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import m from 'moment';

export default {
    name: 'storageCreateSupplier',
    data() {
        return {
            supplier: {
                name: null,
                pseudo: null,
                address: {
                    address: null,
                    city: null,
                    zip: null,
                    region: null,
                    country: null
                }
            }
        };
    },
    watch: {
    },
    mounted() {
        this.countryList();
    },
    computed: {
        ...mapGetters({
            getCountry: 'getCountry'
        }),
    },
    methods: {
        ...mapActions({
            addSupplier: 'addSupplier',
            countryList: 'countryList'
        }),
        async create() {
            
            await this.addSupplier(this.supplier).then(() => {
                this.supplier = {
                    name: null,
                    pseudo: null,
                    address: {
                        address: null,
                        city: null,
                        zip: null,
                        region: null,
                        country: null
                    }
                }
            })
        }
    },
};
</script>