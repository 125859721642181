<template>
    <div class="mt-2">
        <DataTable :value="orders" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
            
            <Column field="created_at" header="Created" style="max-width: 2rem">
                <template #body="{ data }">
                    <p>{{ convertDateDay(data.created_at) || '-' }}</p>
                </template>
            </Column>

            <Column field="countdown" header="End" style="max-width: 2rem">
                <template #body="{ data }">
                    <p>{{ convertDateDay(data.countdown) || '-' }}</p>
                </template>
            </Column>

            <Column field="isbn" header="ISBN" style="max-width: 2rem"></Column>

            <Column field="order_type" header="Type" style="max-width: 1rem">
                <template #body="{ data }">
                    <p>{{ orderType(data.order_type) || '-' }}</p>
                </template>
            </Column>

            <Column field="price" header="€" style="max-width: 1rem">
                <template #body="{ data }">
                    <p>{{ data.price / 100 }}</p>
                </template>
            </Column>

            <Column field="is_pretrade" header="Pretrade" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.is_pretrade, 'pi-times-circle text-red-400': !data.is_pretrade }"></i>
                </template>
            </Column>

            <Column field="sold" header="Sold" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.sold, 'pi-times-circle text-red-400': !data.sold }"></i>
                </template>
            </Column>

            <Column field="check_by_moderator" header="BO Check" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.check_by_moderator, 'pi-times-circle text-red-400': !data.check_by_moderator }"></i>
                </template>
            </Column>

            <Column field="decline_reasons" header="Invalid ?" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.decline_reasons, 'pi-times-circle text-red-400': !data.decline_reasons }"></i>
                </template>
            </Column>

            <Column field="enabled" header="Enabled" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                </template>
            </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button v-if="data.order_uuid" @click="openOrderUUID = data" type="button" icon="pi pi-eye" rounded />
                </template>
            </Column>
        </DataTable>

        <previewVue :openOrderUUID="openOrderUUID" @close="openOrderUUID = null" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import m from 'moment';
import previewVue from './preview.vue';

export default {
    name: 'userOrders',
    components: {
        previewVue
    },
    props: {
        orders: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            openOrderUUID: null,
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        convertDateDay(data) {
            const convert = m(data).format('DD/MM/YYYY HH:mm:ss');

            if (convert === 'Invalid date') {
                return '-';
            }
            return convert;
        },
        orderType(type) {
            if (type === 0) {
                return 'BUY';
            } else if (type === 1) {
                return 'SELL';
            } else if (type === 2) {
                return 'SG';
            } else if (type === 3) {
                return 'ST';
            } else if (type === 4) {
                return 'BT';
            } else {
                return '-';
            }
        }
    }
};
</script>