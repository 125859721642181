import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/auth';

import Login from '@/views/Login.vue';
import DoubleFA from '@/views/DoubleFA.vue';
import Dashboard from '@/views/Dashboard.vue';
import Users from '@/views/Users.vue';
import User from '@/views/User.vue';
import Tickers from '@/views/Tickers.vue';
import Ticker from '@/views/Ticker.vue';
import Autoscraper from '@/views/Autoscraper.vue';
import ScrapingStatistics from '@/views/ScrapingStatistics.vue';
import NormalizedStatistics from '@/views/NormalizedStatistics.vue';
import NormalizedGenerate from '@/views/NormalizedGenerate.vue';
import ReportsBottles from '@/views/ReportsBottles.vue';
import browserHistory from '@/views/HistoryBrowser.vue';
import historyBanIP from '@/views/HistoryBanIP.vue';
import Changelogs from '@/views/Changelogs.vue';
import ActionsByIP from '@/views/ActionsByIP.vue';
import Orders from '@/views/Orders.vue';
import Emergency from '@/views/Emergency.vue';
import ScrapingGenerate from '@/views/ScrapingGenerate.vue';
import Blog from '@/views/Blog.vue';
import Bottler from '@/views/Bottler.vue';
import BackofficeHistory from '@/views/HistoryBackoffice.vue';
import Events from '@/views/Events.vue';
import Statistics from '@/views/Statistics.vue';
import StatisticsXLSX from '@/views/StatisticsXLSX.vue';
import StatisticsBoard from '@/views/StatisticsBoard.vue';
import Storage from '@/views/Storage.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/2fa',
    name: 'doubleFA',
    component: DoubleFA,
    meta: {
      auth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/statistics/xlsx',
    name: 'StatisticsXLSX',
    component: StatisticsXLSX,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/statistics/board',
    name: 'StatisticsBoard',
    component: StatisticsBoard,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/statistics/all',
    name: 'statisticsAll',
    component: Statistics,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/scraping/generate',
    name: 'scrapingGenerate',
    component: ScrapingGenerate,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/scraping/autoscraper',
    name: 'autoscraper',
    component: Autoscraper,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/scraping/statistics',
    name: 'scrapingStatistics',
    component: ScrapingStatistics,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/normalized/generate',
    name: 'normalizedGenerate',
    component: NormalizedGenerate,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/normalized/statistics',
    name: 'normalizedStatistics',
    component: NormalizedStatistics,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/tickers',
    name: 'tickers',
    component: Tickers,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/ticker/:ticker',
    name: 'ticker',
    component: Ticker,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: Users,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/user/:uuid',
    name: 'user',
    component: User,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/orders',
    name: 'orders',
    component: Orders,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/events',
    name: 'events',
    component: Events,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/history/browser',
    name: 'browserHistory',
    component: browserHistory,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/history/actions/ip',
    name: 'ActionsByIP',
    component: ActionsByIP,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/history/banlist',
    name: 'historyBanIP',
    component: historyBanIP,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/history/backoffice',
    name: 'BackofficeHistory',
    component: BackofficeHistory,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/reports',
    name: 'reportsBottles',
    component: ReportsBottles,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/settings/website/blog',
    name: 'blogPost',
    component: Blog,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/settings/website/bottler',
    name: 'bottlerPage',
    component: Bottler,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/settings/website/emergency',
    name: 'emergencyActions',
    component: Emergency,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/storage',
    name: 'storagePage',
    component: Storage,
    meta: {
      auth: true
    }
  },
  {
    path: '/dashboard/changelogs',
    name: 'changeLogs',
    component: Changelogs,
    meta: {
      auth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.auth) {
    next('/');
  } else {
    next();
  }
});

export default router
