<template>
    <div>
        <DataTable v-model:filters="filters" :value="getSuppliers" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty> No suppliers found. </template>

            <Column field="created_at" header="Created at" style="min-width: 2rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="name" header="Name" style="max-width: 3rem"></Column>

            <Column field="pseudo" header="Pseudo" style="max-width: 3rem"></Column>

            <Column field="address" header="Address">
                <template #body="{ data }">
                    <p>{{ data.address.address }}, {{ data.address.zip }} {{ data.address.city }} ({{ data.address.region ? data.address.region + ', ' : data.address.region }}{{ data.address.country }})</p>
                </template>
            </Column>

            <Column field="enabled" header="" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                </template>
            </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="updateSupplierDialog(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>

        <Dialog v-model:visible="dialogSuplierUpdate" modal header="Update supplier" :style="{ width: '90rem' }">

            <InputSwitch v-model="currentsupplierUpdate.enabled" :trueValue="1" :falseValue="0" class="mt-3" />

            <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2 w-3">
                    <label for="name">Name</label>
                    <InputText id="name" :invalid="!currentsupplierUpdate.name" v-model="currentsupplierUpdate.name" placeholder="Name" />
                </div>

                <div class="flex flex-column gap-2 w-3 ml-2">
                    <label for="pseudo">Pseudo (visible by users)</label>
                    <InputText id="pseudo" :invalid="!currentsupplierUpdate.pseudo" v-model="currentsupplierUpdate.pseudo" placeholder="Alias" />
                </div>
            </div>

            <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2 w-6">
                    <label for="address">Address</label>
                    <InputText id="address" :invalid="!currentsupplierUpdate.address.address" v-model="currentsupplierUpdate.address.address" placeholder="Address" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="zip">Postal code</label>
                    <InputText id="zip" :invalid="!currentsupplierUpdate.address.zip" v-model="currentsupplierUpdate.address.zip" placeholder="postal code" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="city">City</label>
                    <InputText id="city" :invalid="!currentsupplierUpdate.address.city" v-model="currentsupplierUpdate.address.city" placeholder="city" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="region">Region</label>
                    <InputText id="region" v-model="currentsupplierUpdate.address.region" placeholder="region" />
                </div>
            </div>

            <div class="flex mt-3 w-12">
                <Dropdown v-model="currentsupplierUpdate.address.country" :invalid="!currentsupplierUpdate.address.country" editable optionLabel="nom_en_gb" :options="getCountry" placeholder="Select a country">
                    <template #option="slotProps">
                        <div class="flex items-center">
                            <div>{{ slotProps.option.nom_en_gb }} ({{ slotProps.option.alpha2 }}) </div>
                        </div>
                    </template>
                </Dropdown>
            </div>

            <div class="flex mt-5 w-12">
                <div class="flex flex-column gap-2 w-2">
                    <label for="delivery">Delivery</label>
                    <InputText id="delivery" :invalid="!currentsupplierUpdate.prices.enter.delivery" v-model="currentsupplierUpdate.prices.enter.delivery" placeholder="Delivery" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_unique_bottle_box">...per box</label>
                    <InputText id="per_unique_bottle_box" :invalid="!currentsupplierUpdate.prices.enter.per_unique_bottle_box" v-model="currentsupplierUpdate.prices.enter.per_unique_bottle_box" placeholder="price per box" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_reference_in_box">...per reference in box</label>
                    <InputText id="per_reference_in_box" :invalid="!currentsupplierUpdate.prices.enter.per_reference_in_box" v-model="currentsupplierUpdate.prices.enter.per_reference_in_box" placeholder="price per reference in same box" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_new_reference">...per new reference</label>
                    <InputText id="per_new_reference" :invalid="!currentsupplierUpdate.prices.enter.per_new_reference" v-model="currentsupplierUpdate.prices.enter.per_new_reference" placeholder="price per new reference" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_bottle_in_box">...per bottle in box</label>
                    <InputText id="per_bottle_in_box" :invalid="!currentsupplierUpdate.prices.enter.per_bottle_in_box" v-model="currentsupplierUpdate.prices.enter.per_bottle_in_box" placeholder="price per bottle in same box" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_bottle_without_barcode">...per without barcode</label>
                    <InputText id="per_bottle_without_barcode" :invalid="!currentsupplierUpdate.prices.enter.per_bottle_without_barcode" v-model="currentsupplierUpdate.prices.enter.per_bottle_without_barcode" placeholder="price per bottle without barcode" />
                </div>
            </div>

            <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2 w-2">
                    <label for="per_month_tep">...per month TEP</label>
                    <InputText id="per_month_tep" :invalid="!currentsupplierUpdate.prices.subscription.per_month_tep" v-model="currentsupplierUpdate.prices.subscription.per_month_tep" placeholder="Subscription user TEP" />
                </div>

                <div class="flex flex-column gap-2 w-2">
                    <label for="per_month_supplier">...per month SUPPLIER</label>
                    <InputText id="per_month_supplier" :invalid="!currentsupplierUpdate.prices.subscription.per_month_supplier" v-model="currentsupplierUpdate.prices.subscription.per_month_supplier" placeholder="Subscription supplier" />
                </div>

                <div class="flex flex-column gap-2 w-2">
                    <label for="per_month_insurance_percent">...Insurance % per bottle</label>
                    <InputText id="per_month_insurance_percent" :invalid="!currentsupplierUpdate.prices.subscription.per_month_insurance_percent" v-model="currentsupplierUpdate.prices.subscription.per_month_insurance_percent" placeholder="Insurance percent" />
                </div>
            </div>

            <div class="flex mt-5 w-12">
                <div class="flex flex-column gap-2 w-2">
                    <label for="picking">Picking</label>
                    <InputText id="picking" :invalid="!currentsupplierUpdate.prices.exit.picking" v-model="currentsupplierUpdate.prices.exit.picking" placeholder="Picking" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_reference">...per reference</label>
                    <InputText id="per_reference" :invalid="!currentsupplierUpdate.prices.exit.per_reference" v-model="currentsupplierUpdate.prices.exit.per_reference" placeholder="price per reference" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_kg">...per KG</label>
                    <InputText id="per_kg" :invalid="!currentsupplierUpdate.prices.exit.per_kg" v-model="currentsupplierUpdate.prices.exit.per_kg" placeholder="price per kg" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="per_document_print">...per document print</label>
                    <InputText id="per_document_print" :invalid="!currentsupplierUpdate.prices.exit.per_document_print" v-model="currentsupplierUpdate.prices.exit.per_document_print" placeholder="price per document print" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="kraft_50_cm">...per kraft (.5m)</label>
                    <InputText id="kraft_50_cm" :invalid="!currentsupplierUpdate.prices.exit.kraft_50_cm" v-model="currentsupplierUpdate.prices.exit.kraft_50_cm" placeholder="price per kraft 50cm" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="air_50_cm">...per air protection (.5m)</label>
                    <InputText id="air_50_cm" :invalid="!currentsupplierUpdate.prices.exit.air_50_cm" v-model="currentsupplierUpdate.prices.exit.air_50_cm" placeholder="price per air protection 50cm" />
                </div>
            </div>

            <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2 w-2">
                    <label for="box_1">Box 1</label>
                    <InputText id="box_1" :invalid="!currentsupplierUpdate.prices.exit.box_1" v-model="currentsupplierUpdate.prices.exit.box_1" placeholder="Box 1" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="box_3">Box 2 & 3</label>
                    <InputText id="box_3" :invalid="!currentsupplierUpdate.prices.exit.box_3" v-model="currentsupplierUpdate.prices.exit.box_3" placeholder="Box 2 & 3" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="box_6">Box 4, 5 & 6</label>
                    <InputText id="box_6" :invalid="!currentsupplierUpdate.prices.exit.box_6" v-model="currentsupplierUpdate.prices.exit.box_6" placeholder="Box 4, 5 & 6" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="round_1">Round box 1</label>
                    <InputText id="round_1" :invalid="!currentsupplierUpdate.prices.exit.round_1" v-model="currentsupplierUpdate.prices.exit.round_1" placeholder="Round TEP box 1" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="round_3">Round box 2 & 3</label>
                    <InputText id="round_3" :invalid="!currentsupplierUpdate.prices.exit.round_3" v-model="currentsupplierUpdate.prices.exit.round_3" placeholder="Round TEP box 2 & 3" />
                </div>

                <div class="flex flex-column gap-2 w-2 ml-2">
                    <label for="round_6">Round box 4, 5 & 6</label>
                    <InputText id="round_6" :invalid="!currentsupplierUpdate.prices.exit.round_6" v-model="currentsupplierUpdate.prices.exit.round_6" placeholder="Round TEP box 4, 5 & 6" />
                </div>
            </div>
            
            <div class="flex justify-content-end gap-2 mt-2">
                <Button type="button" label="Cancel" severity="secondary" @click="dialogSuplierUpdate = false"></Button>
                <Button type="button" label="Update" @click="updupplier()"></Button>
            </div>
        </Dialog>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';

export default {
    name: 'storageSuppliers',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            dialogSuplierUpdate: false,
            currentsupplierUpdate: null
        };
    },
    watch: {
        dialogSuplierUpdate() {
            if (!this.dialogSuplierUpdate) {
                this.currentsupplierUpdate = null;
            } else if (this.getCountry.length === 0) {
                this.countryList();
            }
        }
    },
    computed: {
        ...mapGetters({
            getSuppliers: 'getSuppliers',
            getCountry: 'getCountry'
        }),
    },
    methods: {
        ...mapActions({
            countryList: 'countryList',
            updateSupplier: 'updateSupplier'
        }),
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY');
        },
        updateSupplierDialog(data) {
            this.currentsupplierUpdate = data;
            this.dialogSuplierUpdate = true;
        },
        async updupplier() {
            await this.updateSupplier(this.currentsupplierUpdate).then(() => {
                this.dialogSuplierUpdate = false;
            })
        }
    },
};
</script>