<template>
  <div class="mt-2">
    <Dialog
      v-model:visible="dialog"
      @hide="$emit('close')"
      modal
      header="Order preview"
      :style="{ width: '80vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div
        v-if="openOrderUUID.pictures_attachments"
        class="inputs-row"
        style="padding: 10px 0; min-height: 140px"
      >
        <Image
          :src="picture"
          alt="Bottle picture"
          v-for="(picture, n) in openOrderUUID.pictures_attachments"
          :key="n"
          width="123"
          height="123"
          class="mr-2"
          preview
        />
        <p v-if="!openOrderUUID.pictures_attachments">No picture</p>
      </div>

      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2 px-2 py-2"
      >
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">User :</h4>
          <a @click="redirectToUser(openOrderUUID)" href="#" class="ml-1 mr-2"
            >Check user</a
          >
          <span> ({{ openOrderUUID.firstname.toUpperCase() }} {{ openOrderUUID.lastname.toUpperCase() }})</span>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">ISBN :</h4>
          <p class="my-1">{{ openOrderUUID.isbn }}</p>
          <a
            :href="
              'https://thexchangeplatform.io/product/' + openOrderUUID.isbn
            "
            target="_blank"
            class="ml-2"
            >Check product</a
          >
        </div>
        <!-- <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Title :</h4>
          <p class="my-1">{{ openOrderUUID.price_history[0].title }}</p>
        </div> -->
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Order type :</h4>
          <p class="my-1">{{ orderType(openOrderUUID.order_type) || "-" }}</p>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Fullsize :</h4>
          <p class="my-1">{{ openOrderUUID.fullsize === 1 ? "YES" : "NO" }}</p>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Package dimensions :</h4>
          <p v-if="openOrderUUID.package_dimensions" class="my-1">
            Lenght: {{ openOrderUUID.package_dimensions.length }}
            {{ openOrderUUID.size_unit }} - Width:
            {{ openOrderUUID.package_dimensions.width }}
            {{ openOrderUUID.size_unit }} - Height:
            {{ openOrderUUID.package_dimensions.height }}
            {{ openOrderUUID.size_unit }}
          </p>
          <p v-else class="my-1">N/A</p>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Package weight :</h4>
          <p class="my-1">
            {{ openOrderUUID.package_weight }} {{ openOrderUUID.weight_unit }}
          </p>
        </div>
        <div class="flex align-items-center my-1">
          <h4 class="mx-2 my-0">Quantity :</h4>
          <p class="my-1">{{ openOrderUUID.quantity }}</p>
        </div>
        <div
          v-if="openOrderUUID.check_by_moderator === 0"
          class="flex align-items-center"
        >
          <h4 class="mx-2 my-0">Price :</h4>
          <p class="my-1">
            {{ openOrderUUID.price / 100 }} € - Last price :
            {{
              openOrderUUID.price_history?.length > 0
                ? returnLastPrice(openOrderUUID)
                : "N/A"
            }}
          </p>
        </div>
        <div v-else class="flex align-items-center">
          <h4 class="mx-2 my-0">Price :</h4>
          <p class="my-1">{{ openOrderUUID.price / 100 }} €</p>
        </div>
      </div>

      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2 px-2 py-2"
      >
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Is blocktrade :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500': openOrderUUID.blocktrade_token,
              'pi-times-circle text-red-400': !openOrderUUID.blocktrade_token,
            }"
          ></i>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Is sold :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500': openOrderUUID.sold,
              'pi-times-circle text-red-400': !openOrderUUID.sold,
            }"
          ></i>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Is pretrade :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500': openOrderUUID.is_pretrade,
              'pi-times-circle text-red-400': !openOrderUUID.is_pretrade,
            }"
          ></i>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Declined ? :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500': openOrderUUID.decline_reasons,
              'pi-times-circle text-red-400': !openOrderUUID.decline_reasons,
            }"
          ></i>
        </div>
        <div
          v-if="openOrderUUID.decline_reasons"
          class="flex align-items-center"
        >
          <h4 class="mx-2 my-0">Declined reasons :</h4>
          <p class="mx-2 my-0">{{ openOrderUUID.decline_reasons }}</p>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Enabled :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500': openOrderUUID.enabled,
              'pi-times-circle text-red-400': !openOrderUUID.enabled,
            }"
          ></i>
        </div>
        <div class="flex align-items-center">
          <h4 class="mx-2 my-0">Check by moderator :</h4>
          <i
            class="pi"
            :class="{
              'pi-check-circle text-green-500':
                openOrderUUID.check_by_moderator,
              'pi-times-circle text-red-400': !openOrderUUID.check_by_moderator,
            }"
          ></i>
        </div>
      </div>

      <div
        v-if="openOrderUUID.check_by_moderator === 0"
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2 px-2 py-2"
      >
        <SelectButton
          v-model="declineReason"
          :options="options"
          optionLabel="name"
          multiple
          aria-labelledby="multiple"
        />
        <Button
          @click="orderResponse(0)"
          label="Decline"
          severity="danger"
          class="mt-3"
          :disabled="!declineReason"
        />
      </div>

      <template #footer>
        <Button
          v-if="openOrderUUID.check_by_moderator === 1"
          @click="delOrder(openOrderUUID.order_uuid)"
          label="Delete"
          severity="danger"
        />
        <!-- <Button v-if="openOrderUUID.check_by_moderator === 1" label="Update" severity="warning" /> -->
        <Button
          v-if="openOrderUUID.check_by_moderator === 0"
          @click="orderResponse(1)"
          label="Accept"
          severity="success"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import m from "moment";

export default {
  name: "orderPreview",
  props: {
    openOrderUUID: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      declineReason: null,
      options: [
        { name: "Photo trop sombre :", value: 0 },
        { name: "Photo trop claire :", value: 1 },
        { name: "Plusieurs bouteilles sur la même photo :", value: 2 },
        { name: "Photo floue :", value: 3 },
        { name: "Plusieurs fois la même photo :", value: 4 },
        { name: "Numéro de bouteille caché :", value: 5 },
        { name: "Image modifiée :", value: 6 },
        { name: "Photo différente du ticker :", value: 0 },
        {
          name: "Dimensions incorrectes (longueur/largeur/hauteur) :",
          value: 7,
        },
        { name: "Prix trop bas", value: 8 },
        { name: "Prix trop haut", value: 9 },
        { name: "Poids incorrect :", value: 10 },
      ],
    };
  },
  watch: {
    openOrderUUID() {
      console.log(this.openOrderUUID);
      if (this.openOrderUUID) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      user: "user",
      userStatistics: "userStatistics",
      checkOrder: "checkOrder",
      deleteOrder : 'deleteOrder'
    }),
    convertDateDay(data) {
      const convert = m(data).format("DD/MM/YYYY HH:mm:ss");

      if (convert === "Invalid date") {
        return "-";
      }
      return convert;
    },
    orderType(type) {
      if (type === 0) {
        return "BUY";
      } else if (type === 1) {
        return "SELL";
      } else if (type === 2) {
        return "SG";
      } else if (type === 3) {
        return "ST";
      } else if (type === 4) {
        return "BT";
      } else {
        return "-";
      }
    },
    returnLastPrice(order) {
      if (order.price_history) {
        return (
          order.price_history[0].last_price +
          "€ (" +
          m(order.price_history[0].sold_date).format("DD/MM/YYYY") +
          ")"
        );
      }
    },
    async redirectToUser(data) {
      this.userStatistics(data.user_uuid);

      await this.user(data.user_uuid).then(() => {
        this.$router.push("/dashboard/user/" + data.user_uuid);
      });
    },
    async orderResponse(status) {
      const payload = {
        status: status,
        order_uuid: this.openOrderUUID.order_uuid,
        reasons: [],
      };

      if (this.declineReason) {
        this.declineReason.forEach((reason) => {
          payload.reasons.push(reason.name);
        });
      }

      await this.checkOrder(payload).then(() => {
        this.$emit("close");
      });
    },
    delOrder(orderUUID) {
      this.deleteOrder(orderUUID);
      this.$emit("close");
    }
  },
};
</script>
