import { createStore } from 'vuex';
// import router from '../router/index';
// import { api } from '@/services/interceptors';

/* Store */
import auth from './auth';
import blog from './blog';
import users from './users';
import events from './events';
import orders from './orders';
import search from './search';
import bottler from './bottler';
import history from './history';
import reports from './reports';
import storage from './storage';
import scraping from './scraping';
import statistics from './statistics';
import normalized from './normalized';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    normalized,
    users,
    scraping,
    reports,
    history,
    orders,
    events,
    blog,
    bottler,
    search,
    storage,
    statistics
  }
})
