import { api } from '@/services/interceptors';

export default {
  state: {
    missing: [],
    errors: [],
  },
  getters: {
    getMissingBottles(state) {
        return state.missing;
    },
    getErrorsBottles(state) {
        return state.errors;
    },
  },
  actions: {
    missingBottles({ commit }) {
        api.get('api/reports/bottle/missing').then((res) => {
            commit('STORAGE_MISSING', res.data.reports);
        });
    },
    errorsBottles({ commit }) {
        api.get('api/reports/bottle/error').then((res) => {
            commit('STORAGE_ERRORS', res.data.reports);
        });
    },
    verdict({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.post('api/reports/bottle/verdict', payload).then(() => {
          dispatch('missingBottles');
          dispatch('errorsBottles');
      });
  },
  },
  mutations: {
    STORAGE_MISSING(currentState, missing) {
        currentState.missing = missing;
    },
    STORAGE_ERRORS(currentState, errors) {
        currentState.errors = errors;
    },
    STORE_NOTHING() { }
  }
};