<template>
    <div class="card lg:px-9 mt-3">
        <DataTable v-model:filters="filters" :value="getTickers" :first="saveFirst" @update:first="updateFirst" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="isbn, ticker, title, ean" />
                    </span>
                </div>
            </template>
            <template #empty> No ticker found. </template>

            <Column field="isbn" header="ISBN" sortable style="width: 3rem"></Column>
            <Column field="ticker" header="Ticker" sortable style="width: 3rem"></Column>
            <Column field="title_norm" header="Title" sortable></Column>
            <Column field="count" header="Available" sortable></Column>
            <Column field="ean_generic" header="EAN" sortable></Column>

            <Column field="is_reference" header="">
                <template #body="{ data }">
                    <i v-if="data.is_tep_files" class="pi pi-verified text-blue-500 mr-2"></i>
                    <i v-if="hasReference(data.isbn) && !data.is_tep_files" class="pi pi-star-fill text-yellow-500"></i>
                </template>
                </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <div class="flex card custom-style">
                        <ConfirmPopup></ConfirmPopup>
                        <Button disabled v-if="data.ticker" @click="dialogConfirmMerge(data)" type="button" icon="pi pi-link" class="mr-3" severity="warning" rounded />
                        <Button @click="showTemplate($event, data)" type="button" icon="pi pi-trash" severity="danger" rounded />
                        <Button v-if="data.title_norm" @click="redirectToTicker(data)" type="button" icon="pi pi-angle-right" class="ml-3" rounded />
                    </div>
                </template>
            </Column>
        </DataTable>

        <Dialog v-model:visible="dialogMergeTicker" modal header="Merge ticker with ISBN" :style="{ width: '25rem' }">
            <span class="p-text-secondary block mb-2">Choice target ISBN</span>

            <span class="p-text-secondary block mb-3">{{ originTicker }} becomes {{ targetTicker || '-----' }}</span>

            <div class="flex align-items-center gap-3 mb-3">
                <label for="target" class="font-semibold w-6rem">Target ISBN</label>
                <InputText id="target" v-model="targetTicker" class="flex-auto" autocomplete="off" />
            </div>
            <div class="flex justify-content-end gap-2">
                <Button type="button" label="Cancel" severity="secondary" @click="dialogMergeTicker = false"></Button>
                <Button 
                v-tooltip="{
                    value:
                    '/!\\ Warning, merge change all values for origin bottles',
                    showDelay: 100,
                    hideDelay: 300,
                }"
                type="button" label="Merge" @click="mergeTicker()"></Button>
            </div>
        </Dialog>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import { find } from 'lodash';

export default {
    name: 'normalizedBottles',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            saveFirst: 0,
            dialogMergeTicker: false,
            originTicker: null,
            targetTicker: null,
        };
    },
    computed: {
        ...mapGetters({
            getTickers: 'getTickers',
            getSameTickers: 'getSameTickers',
            getReferences: 'getReferences'
        }),
    },
    watch: {
        'filters.global.value'(val) {
            localStorage.setItem('search', val);
        }
    },
    methods: {
        ...mapActions({
            tickers: 'tickers',
            ticker: 'ticker',
            deleteNormalizedTicker: 'deleteNormalizedTicker',
            putMergeTicker: 'mergeTicker'
        }),
        async redirectToTicker(data) {
            await this.ticker(data.ticker).then(() => {
                this.$router.push('/dashboard/ticker/' + data.ticker);
            })
        },
        hasReference(isbn) {
            const findIsbn = find(this.getReferences, { isbn: isbn });

            if (findIsbn) {
                return true;
            }
        },
        updateFirst(first) {
            localStorage.setItem('first', first);
        },
        showTemplate(event, ticker) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Delete ticker and all bottles associated ?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-sm',
                acceptClass: 'p-button-secondary p-button-outlined p-button-sm',
                rejectLabel: 'Cancel',
                acceptLabel: 'Delete',
                accept: () => {
                    this.deleteNormalizedTicker(ticker.isbn);
                },
                reject: () => {
                    console.log('nok');
                }
            });
        },
        dialogConfirmMerge(ticker) {
            this.originTicker = ticker.isbn;
            this.dialogMergeTicker = true;
        },
        async mergeTicker() {
            const payload = {
                origin_ticker: this.originTicker,
                target_ticker: this.targetTicker
            }
            
            if (payload.origin_ticker === payload.target_ticker) {
                this.$toast.add({ severity: 'error', summary: `Same ISBN`, detail: 'Origin and target ISBN is same. Please check your entries', life: 5000 });
            } else {
                await this.putMergeTicker(payload).then(() => {
                    this.$toast.add({ severity: 'success', summary: `Success merge`, detail: 'Merge OK', life: 3000 });
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: `Failed merge`, detail: 'Merge Failed !', life: 3000 });
                }).finally(() => {
                    this.dialogMergeTicker = false;
                    this.originTicker = null;
                    this.targetTicker = null;
                })
            }
        }
    },
    mounted() {
        this.tickers();

        const getFirstItemSave = localStorage.getItem('first');

        if (getFirstItemSave) {
            this.saveFirst = Number(getFirstItemSave);
        } else {
            this.saveFirst = 0;
        }

        const isSearchSave = localStorage.getItem('search');
        if (isSearchSave) {
            this.filters['global'].value = isSearchSave;
        }
    }
};
</script>

<style scoped>
.custom-style {
    height: 30px;
}
</style>