import { api } from '@/services/interceptors';

export default {
  state: {
    tickers: [],
    references: [],
    sameTickers: [],
    SameTickersInfos: null,
    categories: [],
    emergency: [],
  },
  getters: {
    getTickers(state) {
        return state.tickers;
    },
    getReferences(state) {
      return state.references;
  },
    getSameTickers(state) {
        return state.sameTickers;
    },
    getSameTickersInfos(state) {
      return state.SameTickersInfos;
    },
    getNormalizedCategories(state) {
      return state.categories;
    },
    getEmergency(state) {
      return state.emergency;
    },
  },
  actions: {
    tickers({ commit }) {
        api.get('api/normalized/tickers').then((res) => {
            commit('STORAGE_TICKERS', res.data.tickers);
            commit('STORAGE_REFERENCES', res.data.references);
        });
      },
      ticker({ commit }, payload) {
          api.get('api/normalized/ticker/' + payload).then((res) => {
            commit('STORAGE_SAME_TICKERS', res.data);
        });
      },
      reference({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        api.get('api/normalized/bottle/reference/' + payload.ticker + '/' + payload.id).then(() => {
          dispatch('ticker', payload.ticker);
      });
    },
      downloadNormalizedXLSX({ commit }, payload) {
        commit('STORE_NOTHING');
        api.post('api/normalized/generate/xlsx', payload, { responseType: 'arraybuffer', responseEncoding: 'binary', headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
      updateNormalizedBottle({ commit }, payload) {
        api.put('api/normalized/bottle', payload).then((res) => {
          commit('STORAGE_SAME_TICKERS', res.data);
        });
      },
      updateNormalizedTicker({ commit }, payload) {
          api.put('api/normalized/ticker', payload).then((res) => {
            commit('STORAGE_SAME_TICKERS', res.data);
        });
      },
      updateReleaseTicker({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.put('api/normalized/ticker/release', payload).then((res) => {
            commit('STORAGE_SAME_TICKERS', res.data);
            resolve();
        }).catch(() => {
          reject();
        })
      });
    },
      updateFilesAttachments({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit('STORE_NOTHING');
          api.put('api/normalized/files-attachments', payload).then(() => {
            // dispatch('ticker', payload.isbn);
            resolve();
          }).catch(() => {
            reject();
          })
        })
    },
      deleteNormalizedBottle({ commit }, payload) {
        api.delete('api/normalized/bottle/' + payload.id + '/' + payload.ticker).then((res) => {
          commit('STORAGE_SAME_TICKERS', res.data);
        });
      },
      deleteNormalizedTicker({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        api.delete('api/normalized/bottle/' + payload).then(() => {
          dispatch('tickers');
        });
      },
      normalizedStatistics({ commit }) {
        api.get('api/normalized/statistics').then((res) => {
            commit('STORAGE_NORMALIZED_STATISTICS', res.data.statistics);
        });
      },
      emergencyList({ commit }) {
        api.get('api/server/normalized/emergency').then((res) => {
            commit('STORAGE_EMERGENCY', res.data.emergency);
        });
      },
      updateEmergencyList({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
          commit('STORE_NOTHING');
          api.put('api/server/normalized/emergency', payload).then(() => {
            dispatch('emergencyList');
            resolve();
          }).catch(() => {
            reject();
          })
        })
      },
      mergeTicker({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
          commit('STORE_NOTHING');
          api.put('api/normalized/ticker/merge', payload).then(() => {
            resolve();
            dispatch('tickers');
          }).catch(() => {
            reject();
          })
        })
      },
  },
  mutations: {
    SET_AUTH(currentState, state) {
        currentState.auth = state;
    },
    STORAGE_TICKERS(currentState, tickers) {
        currentState.tickers = tickers;
    },
    STORAGE_REFERENCES(currentState, references) {
      currentState.references = references;
    },
    STORAGE_SAME_TICKERS(currentState, sameTickers) {
        currentState.sameTickers = sameTickers.ticker;
        currentState.SameTickersInfos = sameTickers.infos;
    },
    STORAGE_NORMALIZED_STATISTICS(currentState, statistics) {
      currentState.categories = statistics.categories;
    },
    STORAGE_EMERGENCY(currentState, emergency) {
      currentState.emergency = emergency;
    },
    STORE_NOTHING() { }
  }
};