<template>
    <div class="card lg:px-9">
        <DataTable v-model:filters="filters" :value="getUsers" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty> No users found. </template>

            <Column field="created_at" header="Date" style="max-width: 4rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="account_type" header="Type" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-user': !data.account_type, 'pi-building': data.account_type }"></i>
                </template>
            </Column>

            <Column field="names" header="Name" dataType="boolean" style="max-width: 6rem">
                <template #body="{ data }">
                    <p v-if="data.organization">{{ data.organization.name.toUpperCase() }} ({{ data.firstname }} {{ data.lastname.toUpperCase() }})</p>
                    <p v-else>{{ data.firstname }} {{ data.lastname.toUpperCase() }}</p>
                </template>
            </Column>

            <Column field="subscriptions_informations" header="Sub" dataType="boolean" style="max-width: 4rem">
                <template #body="{ data }">
                    <Tag class="mx-1 my-1" :value="s.label.slice(0, 1)" v-for="s, n in returnSubscriptionsCount(data.subscriptions_informations)" :key="n"></Tag>
                    <p v-if="returnSubscriptionsCount(data.subscriptions_informations).length === 0">-</p>
                </template>
            </Column>

            <Column field="newsletter" header="NL" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.newsletter, 'pi-times-circle text-red-400': !data.newsletter }"></i>
                </template>
            </Column>

            <Column field="email_is_verified" header="S1" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.email_is_verified, 'pi-times-circle text-red-400': !data.email_is_verified }"></i>
                </template>
            </Column>

            <Column field="phone_is_verified" header="S2" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.phone_is_verified, 'pi-times-circle text-red-400': !data.phone_is_verified }"></i>
                </template>
            </Column>

            <Column field="kyc_is_verified" header="KYC" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.kyc_is_verified, 'pi-times-circle text-red-400': !data.kyc_is_verified }"></i>
                </template>
            </Column>

            <Column field="banking_is_verified" header="S4" dataType="boolean" style="max-width: 2rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.banking_is_verified, 'pi-times-circle text-red-400': !data.banking_is_verified }"></i>
                </template>
            </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="redirectToUser(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import { filter } from 'lodash';
import m from 'moment';

export default {
    name: 'usersList',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    computed: {
        ...mapGetters({
            getUsers: 'getUsers',
            getUser: 'getUser'
        }),
    },
    methods: {
        ...mapActions({
            users: 'users',
            user: 'user',
            userStatistics: 'userStatistics'
        }),
        async redirectToUser(data) {
            this.userStatistics(data.uuid);
            
            await this.user(data.uuid).then(() => {
                this.$router.push('/dashboard/user/' + data.uuid);
            })
        },
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY');
        },
        returnSubscriptionsCount(subscriptions) {
            if (subscriptions.length === 0) {
                return [];
            } else {
                const countFilterEnabled = filter(subscriptions, { subscription_state: 1 });

                return countFilterEnabled;
            }
        },
    },
    mounted() {
        this.users();
    }
};
</script>