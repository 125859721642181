<template>
  <div
    v-if="getUsersStatistics"
    class="surface-ground py-5 lg:px-9"
  >
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Users accounts</span
              >
              <div class="text-900 font-medium text-xl">
                {{ getUsersStatistics.users_total }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-users text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{ getUsersStatistics.users_last_day }} new
          </span>
          <span class="text-500">since 24h</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Verified email</span
              >
              <div class="text-900 font-medium text-xl">
                {{ getUsersStatistics.users_email_total }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-at text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{ getUsersStatistics.users_email_last_day }} new
          </span>
          <span class="text-500">since 24h</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Verified Phone</span
              >
              <div class="text-900 font-medium text-xl">
                {{ getUsersStatistics.users_phone_total }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-phone text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{ getUsersStatistics.users_phone_last_day }} new
          </span>
          <span class="text-500">since 24h</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">KYCed accouts</span>
              <div class="text-900 font-medium text-xl">
                {{ getUsersStatistics.users_kyc_total }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-id-card text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{ getUsersStatistics.users_kyc_last_day }} new
          </span>
          <span class="text-500">since 24h</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Visitors</span>
              <div class="text-900 font-medium text-xl">
                {{ getUsersStatistics.visitors }} <span style="font-size: 12px;">today</span>
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-500 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-user text-white text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">{{ returnLiveVisitors }} live</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-2">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Orders</span>
              <div class="text-900 font-medium text-xl">
               ({{ getUnverifiedOrders.length }}) {{ getUsersStatistics.orders }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-300 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-list text-white text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">{{ getUsersStatistics.orders_sum_buy }} €</span> - 
          <span class="text-red-500 font-medium">{{ getUsersStatistics.orders_sum_sell }} €</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { find } from 'lodash';

export default {
  name: 'dashboardStatistics',
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getUsersStatistics: 'getUsersStatistics',
      getUnverifiedOrders: 'getUnverifiedOrders',
      getProdStats: 'getProdStats'
    }),
    returnLiveVisitors() {
      const findVisitors = find(this.getProdStats, (o) => {
        return o.code === 'current_visitors';
      })

      return findVisitors.int_value;
    }
  },
};
</script>
