<template>
    <div class="card lg:px-9">
        <div class="card">
            <DataTable v-model:filters="filters" :value="getBackoffice" stripedRows paginator :rows="25" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                        </span>
                    </div>
                </template>
                <template #empty> Not found. </template>

                <Column field="created_at" header="Created at" sortable>
                    <template #body="{ data }">
                        <span>{{ convertDate(data.created_at) }}</span>
                    </template>
                </Column>

                <Column field="email" header="Created by" sortable></Column>

                <Column field="type" header="Type" sortable></Column>

                <Column field="reason" header="Note" sortable></Column>

                <Column field="request" header="Request" sortable></Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <div class="flex">
                            <Button v-if="data.customer_uuid" @click="redirectToUser(data)" type="button" icon="pi pi-user" rounded />
                            <Button v-if="data.new_value || data.old_value" @click="info(data)" type="button" icon="pi pi-search" class="ml-3" rounded />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog v-model:visible="visible" modal header="History infos" :style="{ width: '90vw' }">
            <p v-if="history.old_value" class="m-0">
                <span>Before update: </span>
                {{ history.old_value }}
            </p>
            <p v-if="history.new_value" class="m-0">
                <span>After update: </span>
                {{ history.new_value }}
            </p>
        </Dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'historyBackoffice',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            visible: false,
            history: null
        };
    },
    computed: {
        ...mapGetters({
            getBackoffice: 'getBackoffice'
        }),
    },
    methods: {
        ...mapActions({
            historyBackoffice: 'historyBackoffice',
            user: 'user',
            userStatistics: 'userStatistics'
        }),
        convertDate(data) {
            if (data) {
                return m(data).format('DD/MM/YYYY HH:mm:ss');
            }
            return '-';
        },
        info(data) {
            this.history = data;
            this.visible = true;
        },
        async redirectToUser(data) {
            this.userStatistics(data.customer_uuid);
            
            await this.user(data.customer_uuid).then(() => {
                this.$router.push('/dashboard/user/' + data.customer_uuid);
            })
        },
    },
    mounted() {
        this.historyBackoffice();
    }
};
</script>