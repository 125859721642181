<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="mt-2">

            <DataTable v-model:filters="filters" :value="getArticles" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </template>
                <template #empty> No articles found. </template>

                <Column field="title" header="Title"></Column>
                <Column field="main_img" header="Main_img"></Column>
                <!-- <Column field="content" header="Content"></Column> -->
                <Column field="created_at" header="Date Publication">
                    <template #body="{ data }">
                        {{ displayDate(data.created_at) }}
                    </template>
                </Column>
                <Column field="updated_at" header="Date Update">
                    <template #body="{ data }">
                        {{ displayDate(data.updated_at) }}
                    </template>
                </Column>

                <Column header="Tags" headerStyle="width: 10rem">
                    <template #body="{ data }">
                        <span v-for="tag in data.tags" :key="tag">{{ tag.name }}<br/></span>
                    </template>
                </Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <div class="flex gap-2">
                            <Button @click="removeArticle(data.id)" type="button" icon="pi pi-trash" rounded />
                            <Button @click="updateArticleDialog(data)" type="button" icon="pi pi-pencil" rounded />
                        </div>
                    </template>
                </Column>
            </DataTable>

            <Dialog header="Article Details" v-model:visible="dialogVisible" :modal="true" :closable="true" @hide="reinitSelectedArticle" class="dialogUpdate">
                <div v-if="selectedArticle"  class="flex flex-column gap-2">
                
                <div>
                    <div class="flex flex-column gap-2">
                        <label for="title">Titre</label>
                        <InputText id="title" v-model="selectedArticle.title" />
                    </div>
                </div>

                <div>
                    <div class="flex gap-2">
                        <label for="created_at" class="align-self-center">Date of publication :</label>
                        <Calendar v-model="selectedArticle.created_at" dateFormat="dd/mm/yy" disabled="true"/>
                    </div>
                </div>

                <Editor v-model="selectedArticle.content" editorStyle="height: 320px" />

                <div class="form-group">
                    <label for="video-upload">Ajouter une vidéo : </label>
                    <!-- <FileUpload mode="basic" accept="video/*" :maxFileSize="5000000" :auto="true" @upload="onFileChange" chooseLabel="Ajouter une vidéo" /> -->
                    <input type="file" id="video-upload" @change="onFileChange" accept="video/*" />
                    <p>Indiquez &lt;video&gt; dans le contenu ci-dessus à l'endroit où vous voulez insérer la vidéo</p>
                </div>

                <MultiSelect v-model="this.selectedTags" display="chip" :options="getTags" optionLabel="name" placeholder="Select Tags"
                    class="w-full md:w-20rem" />

                <Button
                    @click="editArticle()"
                    label="Update article"
                    severity="success"
                    :disabled="!selectedArticle.title || !selectedArticle.created_at || !selectedArticle.content"
                />
                </div>
            </Dialog>

        </div>

        <div v-if="active === 1" class="mt-2 flex flex-column gap-2">
            <div>
                <div class="flex flex-column gap-2">
                    <label for="title">Titre</label>
                    <InputText id="title" v-model="article.title" required/>
                </div>
            </div>


            <div>
                <div class="flex gap-2">
                    <label for="created_at" class="align-self-center">Date of publication :</label>
                    <Calendar v-model="date" dateFormat="dd/mm/yy" @input="updateDate" required/>
                </div>
            </div>


            <Editor v-model="article.content" editorStyle="height: 320px" />


            <!-- <AutoComplete v-model="article.tags" multiple option-label="name" :suggestions="getTags" @complete="addNewTag" /> -->

            <div class="form-group">
                <label for="video-upload">Ajouter une vidéo : </label>
                <!-- <FileUpload mode="basic" accept="video/*" :maxFileSize="5000000" :auto="true" @upload="onFileChange" chooseLabel="Ajouter une vidéo" /> -->
                <input type="file" id="video-upload" @change="onFileChange" accept="video/*" />
                <p>Indiquez &lt;video&gt; dans le contenu ci-dessus à l'endroit où vous voulez insérer la vidéo</p>
            </div>

            <MultiSelect v-model="article.tags" display="chip" :options="getTags" optionLabel="name" placeholder="Select Tags"
                class="w-full md:w-20rem" />

            <Button
            @click="createArticle()"
            label="Create article"
            severity="success"
            :disabled="!article.title || !date || !article.content || (article.tags && article.tags.length == 0)"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import { api } from '@/services/interceptors';

export default {
    name: 'blogPost',
    data() {
        return {
            active: 0,
            items: [
                { label: 'Article list', icon: 'pi pi-list' },
                { label: 'Add article', icon: 'pi pi-plus' }
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            article: {
                title: null,
                main_img: null,
                content: null,
                created_at: null,
                updated_at: null,
                tags: [],
                video: ''
            },
            date: '',
            tagsInput: '',
            dialogVisible: false,
            selectedArticle: null,
            formattedDate: '',
            allTags: [],
            selectedTags: [],
            newTag: ''
        };
    },
    computed: {
        ...mapGetters({
            getArticles: 'getArticles',
            getTags: 'getTags'
        }),
    },
    methods: {
        ...mapActions({
            articles: 'articles',
            tags: 'tags',
            addArticle: 'addArticle',
            deleteArticle: 'deleteArticle',
            updateArticle: 'updateArticle',
            addTag: 'addTag'
        }),
        async onFileChange(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('video', file);
            console.log(formData);
            try {
                const response = await api.post('api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                this.article.video = response.data;
            } catch (error) {
                console.error('Error uploading files', error);
            }
        },
        async createArticle() {
            this.article.created_at = this.formatDate(this.date);
            await this.addArticle(this.article).then(() => {
                this.articles();
            })

            this.article = {
                title: null,
                main_img: null,
                content: null,
                created_at: null,
                updated_at: null,
                video: ''
            }
            this.date = '';
            this.selectedTags = [],
            this.tagsInput = '';
            this.active = 0;
        },
        reinitSelectedArticle() {
            this.selectedTags = [];
        },
        async editArticle() {
            this.selectedArticle.created_at = this.formatDate(this.selectedArticle.created_at);
            this.selectedArticle.updated_at = this.formatDate(new Date());
            this.selectedArticle.tags = this.selectedTags;
            console.log('selectedArticle : ', this.selectedArticle);
            this.updateArticle(this.selectedArticle).then(() => {
                this.articles();
            })
            this.dialogVisible = false;
        },
        removeArticle(articleId) {
            if (confirm('Are you sure you want to delete this article?')) {
                this.deleteArticle(articleId).then(() => {
                this.articles();
                }).catch(error => {
                console.error('Error deleting article:', error);
                alert('Failed to delete the article');
                });
            }
        },
        handleFileUpload(event) {
            this.article.main_img = event.target.files[0];
            console.log(this.article.main_img);
        },
        decodeHTMLEntities(text) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            return doc.documentElement.textContent;
        },
        updateArticleDialog(article) {
            this.selectedArticle = {...article};
            this.selectedArticle.created_at = this.displayDate(this.selectedArticle.created_at);
            this.selectedArticle.tags.forEach(tag => {
                this.selectedTags.push(tag);
            })
            this.selectedArticle.created_at = this.parseDate(this.selectedArticle.created_at);
            this.dialogVisible = true;
        },
        formatDate(date) {
            if (date == null)
                return;
            const parseDate = new Date(date);
            const year = parseDate.getFullYear();
            const month = String(parseDate.getMonth() + 1).padStart(2, '0'); 
            const day = String(parseDate.getDate()).padStart(2, '0'); 
            const hours = String(parseDate.getHours()).padStart(2, '0'); 
            const minutes = String(parseDate.getMinutes()).padStart(2, '0'); 
            const seconds = String(parseDate.getSeconds()).padStart(2, '0'); 

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        displayDate(isoDate) {
            if (!isoDate)
                return;
            const dateObj = new Date(isoDate);
            const day = String(dateObj.getDate()).padStart(2, '0'); // Ajoute un zéro en tête si nécessaire
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Ajoute un zéro en tête si nécessaire
            const year = dateObj.getFullYear();
            return `${day}/${month}/${year}`;
        },
        updateDate(date) {
            this.selectedArticle.created_at = date;
        },
        parseDate(dateString) {
            const [day, month, year] = dateString.split('/').map(Number);
            return new Date(year, month - 1, day);
        },
        addNewTag() {
        if (this.newTag.trim()) {
            const newTagObject = { name: this.newTag.trim() };
            if (!this.getTags.some(tag => tag.name === this.newTag.trim())) {
                this.addTag(newTagObject);
                this.article.tags.push(newTagObject);
            }
            if (this.getTags.some(tag => tag.name === this.newTag.trim())) {
                this.article.tags.push(newTagObject);
            }
            this.newTag = '';
            }
            this.tags();
        },
        removeTag(index) {
            this.article.tags.splice(index, 1);
        },
    },
    mounted() {
        this.articles();
        this.tags();
    }
};
</script>

<style>
.dialogUpdate {
    width: 75vw !important;
}
</style>