<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="mt-2">
            <bottlesList />
        </div>

        <div v-if="active === 1" class="mt-2">
            <suppliers />
        </div>

        <div v-if="active === 2" class="mt-2 flex flex-column gap-2">
            <create />
        </div>

        <div v-if="active === 3" class="mt-2 flex flex-column gap-2">
            <createSupplier />
        </div>

        <div v-if="active === 4" class="mt-2 flex flex-column gap-2">
            4
        </div>

        <div v-if="active === 5" class="mt-2 flex flex-column gap-2">
            <country />
        </div>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import create from '@/components/dashboard/modules/storage/create.vue';
import suppliers from '@/components/dashboard/modules/storage/suppliers.vue';
import createSupplier from '@/components/dashboard/modules/storage/createSupplier.vue';
import bottlesList from '@/components/dashboard/modules/storage/bottlesList.vue';
import country from '@/components/dashboard/modules/storage/country.vue';
// import { FilterMatchMode } from 'primevue/api';
// import m from 'moment';

export default {
    components: { create, suppliers, createSupplier, bottlesList, country },
    name: 'storagePage',
    data() {
        return {
            active: 0,
            items: [
                { label: 'Bottles', icon: 'pi pi-list' },
                { label: 'Suppliers', icon: 'pi pi-list' },
                { label: 'Add bottle', icon: 'pi pi-plus' },
                { label: 'Add supplier', icon: 'pi pi-plus' },
                { label: 'Statistics', icon: 'pi pi-chart-pie' },
                { label: 'Country', icon: 'pi pi-globe' },
            ],
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    watch: {
        active() {
            if (this.active === 1) {
                this.suppliers();
            }
        }
    },
    methods: {
        ...mapActions({
            suppliers: 'suppliers',
            bottles: 'bottles'
        }),
    },
    mounted() {
    }
};
</script>

<style>
</style>