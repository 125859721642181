<template>
    <div class="card lg:px-9">
        <div class="flex">
            <Button @click="dialogCreateEvent = true" type="button" icon="pi pi-plus" label="Add new event" severity="success" />
        </div>
        <DataTable v-model:filters="filters" :value="filterEventsList" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end align-items-center">
                    <InputSwitch :trueValue="1" :falseValue="0" v-model="seeOldEvents" class="mr-3" />
                    <p class="mr-5">Display only past events</p>
    
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </div>
            </template>
            <template #empty> No events found. </template>

            <Column field="name" header="Name" sortable></Column>
            <Column field="country" header="Country" sortable></Column>
            <Column field="city" header="City" sortable></Column>
            <Column field="dates" header="Dates" sortable>
                <template #body="{ data }">
                        <p>{{ String(data.dates) }}</p>
                    </template>
            </Column>
            <Column field="month" header="Month" sortable></Column>
            <Column field="spirit_type_label" header="Label" sortable></Column>
            <Column field="contacts" header="Contacts" sortable></Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <div class="flex">
                        <ConfirmPopup></ConfirmPopup>
                        <a :href="data.url" target="_blank" rel="noopener noreferrer" class="mr-2">
                            <Button type="button" icon="pi pi-window-maximize" rounded />
                        </a>
                        <Button @click="currentEvent = data" type="button" icon="pi pi-pencil" class="mr-2" rounded />
                        <Button @click="showTemplate($event, data)" type="button" icon="pi pi-trash" severity="danger" rounded />
                    </div>
                </template>
            </Column>
        </DataTable>

        <Dialog
            v-model:visible="dialogCreateEvent"
            @hide="dialogCreateEvent = false"
            modal
            header="Add new event"
            :style="{ width: '80vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        >
            <div>
                <div class="flex flex-column gap-2">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="event.name" />
                </div>
            </div>

            <div class="flex mt-2 flex-wrap">
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="country">Country</label>
                    <InputText id="country" v-model="event.country" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="city">City</label>
                    <InputText id="city" v-model="event.city" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="dates">Dates</label>
                    <InputText id="dates" placeholder="01/07/2024;02/07/2024" v-model="event.dates" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="spirit_type_label">Spirit type label</label>
                    <MultiSelect v-model="event.spirit_type_label" :options="spirits" placeholder="Select spirit(s)" class="w-full md:w-100" />
                    <!-- <InputText id="spirit_type_label" placeholder="Rhum;Gin" v-model="event.spirit_type_label" /> -->
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="month">Month</label>
                    <MultiSelect v-model="event.month" :options="months" placeholder="Select month(s)" class="w-full md:w-100" />
                    <!-- <InputText id="month" v-model="event.month" /> -->
                </div>
                <div class="flex flex-column gap-2 mt-2">
                    <label for="contacts">Contacts</label>
                    <InputText id="contacts" v-model="event.contacts" aria-describedby="contacts-help"/>
                    <small id="contacts-help">Not display for users</small>
                </div>
            </div>

            <div class="mt-4">
                <div class="flex flex-column gap-2">
                    <label for="url">Url</label>
                    <InputText id="url" v-model="event.url" />
                </div>
            </div>

            <template #footer>
                <Button
                    @click="createEvent()"
                    label="Add"
                    severity="success"
                    :disabled="!event.spirit_type_label || !event.name || !event.month"
                />
            </template>
        </Dialog>

        <Dialog
            v-model:visible="dialogUpdateEvent"
            @hide="currentEvent = false"
            modal
            header="Add new event"
            :style="{ width: '80vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        >
            <div>
                <div class="flex flex-column gap-2">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="currentEvent.name" />
                </div>
            </div>

            <div class="flex mt-2 flex-wrap">
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="country">Country</label>
                    <InputText id="country" v-model="currentEvent.country" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="city">City</label>
                    <InputText id="city" v-model="currentEvent.city" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="dates">Dates</label>
                    <InputText id="dates" placeholder="01/07/2024;02/07/2024" v-model="currentEvent.dates" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="spirit_type_label">Spirit type label</label>
                    <InputText id="spirit_type_label" placeholder="Rhum;Gin" v-model="currentEvent.spirit_type_label" />
                </div>
                <div class="flex flex-column gap-2 mr-3 mt-2">
                    <label for="month">Month</label>
                    <InputText id="month" v-model="currentEvent.month" />
                </div>
                <div class="flex flex-column gap-2 mt-2">
                    <label for="contacts">Contacts</label>
                    <InputText id="contacts" v-model="currentEvent.contacts" aria-describedby="contacts-help"/>
                    <small id="contacts-help">Not display for users</small>
                </div>
            </div>

            <div class="mt-4">
                <div class="flex flex-column gap-2">
                    <label for="url">Url</label>
                    <InputText id="url" v-model="currentEvent.url" />
                </div>
            </div>

            <template #footer>
                <Button
                    @click="editEvent()"
                    label="Edit"
                    severity="warning"
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';

export default {
    name: 'eventsList',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },

            currentEvent: null,
            dialogCreateEvent: false,
            dialogUpdateEvent: false,

            seeOldEvents: 0,

            event: {
                name: null,
                country: null,
                city: null,
                dates: null,
                month: null,
                spirit_type_label: null,
                contacts: null,
                url: null,
            },

            months: [
                "January", 
                "February", 
                "March", 
                "April", 
                "May", 
                "June", 
                "July", 
                "August", 
                "September", 
                "October", 
                "November", 
                "December"
            ],

            spirits: [
                "Agave",
                "American Whisky",
                "Armagnac",
                "Bourbon",
                "Cognac",
                "Gin",
                "Liqueur",
                "Rhum",
                "Whisky",
                "Mixed",
            ]
        };
    },
    watch: {
        currentEvent() {
            if (this.currentEvent) {
                this.dialogUpdateEvent = true;
            } else {
                this.dialogUpdateEvent = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            getEvents: 'getEvents',
        }),
        filterEventsList() {
            if (this.seeOldEvents === 1) {
                const today = m();
                const oldEvents = [];

                this.getEvents.forEach(element => {
                    if (element.dates) {
                        const datesSplit = element.dates.split(';');
                        if (m(datesSplit[0], 'DD/MM/YYYY').isBefore(today)) {
                            oldEvents.push(element)
                        }
                    }
                });

                return oldEvents;
            }

            const today = m();
            const nextEvents = [];

            this.getEvents.forEach(element => {
                if (element.dates) {
                    const datesSplit = element.dates.split(';');
                    if (m(datesSplit[0], 'DD/MM/YYYY').isAfter(today)) {
                        nextEvents.push(element)
                    }
                }
            });

            return nextEvents;
        }
    },
    methods: {
        ...mapActions({
            events: 'events',
            addEvent: 'addEvent',
            updateEvent: 'updateEvent',
            deleteEvent: 'deleteEvent'
        }),
        createEvent() {
            this.event.month = this.event.month.join('-');
            this.event.spirit_type_label = this.event.spirit_type_label.join(';');
            this.addEvent(this.event);
            this.dialogCreateEvent = false;

            this.event = {
                name: null,
                country: null,
                city: null,
                dates: null,
                month: null,
                spirit_type_label: null,
                contacts: null,
                url: null,
            }
        },
        editEvent() {
            this.updateEvent(this.currentEvent);
            this.currentEvent = null;
        },
        delEvent(id) {
            this.deleteEvent(id);
        },
        showTemplate(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to proceed ?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-sm',
                acceptClass: 'p-button-secondary p-button-outlined p-button-sm',
                rejectLabel: 'Cancel',
                acceptLabel: 'Delete',
                accept: () => {
                    this.delEvent(data.id);
                },
                reject: () => {
                    console.log('nok');
                }
            });
        },
    },
    mounted() {
        this.events();
    }
};
</script>