<template>
  <div v-if="getSameTickersInfos" class="m-center lg:px-9">
    <div class="surface-card p-4 shadow-2 border-round">
      <Image
        :src="bottle"
        :alt="bottle"
        v-for="(bottle, n) in getSameTickersInfos.files_attachments"
        :key="n"
        width="123"
        height="123"
        class="mr-2"
        preview
      >
        <template #indicatoricon>
          <i class="pi pi-search"></i>
        </template>
      </Image>

      <div class="text-3xl font-medium text-900 mb-3">
        {{ getSameTickersInfos.title }} - {{ getSameTickersInfos.ticker }} - ({{
          getSameTickersInfos.count
        }})
      </div>

      <Button @click="dialogUploadPictures()" type="button" icon="pi pi-upload" class="mb-2" />

      <div class="font-medium text-500 mb-3">
        Composite ticker: {{ getSameTickersInfos.ticker_composite || "x" }} -
        Ref ticker: {{ getSameTickersInfos.ref_ticker || "x" }}
      </div>
      <div class="font-medium text-500 mb-3">
        Barcode: {{ getSameTickersInfos.ean_generic || "x" }} - Vlookup EAN:
        <i
          class="pi"
          :class="{
            'pi-check-circle text-green-500':
              getSameTickersInfos.allow_vlookup_ean === 1,
            'pi-times-circle text-red-400':
              !getSameTickersInfos.allow_vlookup_ean,
          }"
        ></i>
      </div>
      <DataTable
        v-model:filters="filters"
        :value="getSameTickers"
        stripedRows
        paginator
        :rows="50"
        :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]"
        tableStyle="min-width: 50rem"
      >
        <template #header>
          <div class="flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="date, price, tickerizer..."
              />
            </span>
          </div>
        </template>
        <template #empty> No ticker found. </template>

        <Column field="sold_date" header="Date" sortable></Column>
        <Column field="price" header="Origin price" sortable></Column>
        <Column field="currency" header="Source"></Column>
        <Column field="eur" header="EUR" sortable></Column>
        <Column field="tickerized_by" header="Tickerizer"></Column>
        <Column field="allow_vlookup" header="VL name"></Column>

        <Column field="is_reference" header="">
          <template #body="{ data }">
            <i
              v-if="data.is_reference"
              class="pi pi-star-fill text-yellow-500"
            ></i>
          </template>
        </Column>

        <Column field="" header="">
          <template #body="{ data }">
            <i
              v-if="data.files_attachments"
              class="pi pi-images text-green-500"
            ></i>
          </template>
        </Column>

        <Column
          headerStyle="width: 5rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="{ data }">
            <a :href="data.url" target="_blank" rel="noopener noreferrer">
              <Button type="button" icon="pi pi-window-maximize" rounded />
            </a>
          </template>
        </Column>

        <Column
          headerStyle="width: 5rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="{ data }">
            <Button
              @click="seeBottleDetail(data)"
              type="button"
              icon="pi pi-angle-right"
              rounded
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="dialog"
      modal
      :header="dialogTitle"
      :style="{ width: '80vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="mb-3" v-if="bottle.is_reference">
        <Tag
          icon="pi pi-star-fill"
          severity="warning"
          value="Bottle reference"
        ></Tag>
      </div>

      <Carousel
        :value="getSameTickers"
        :numVisible="1"
        :page="page"
        :numScroll="1"
        :showIndicators="false"
        @update:page="updatePage"
      >
        <template #item="slotProps">
          <p>
            Bottle n°{{ slotProps.index + 1 }}/{{ getSameTickersInfos.count }}
          </p>
        </template>
      </Carousel>

      <Button
        v-if="bottle.files_attachments"
        v-tooltip="{
          value: 'Change the meaning',
          showDelay: 100,
          hideDelay: 300,
        }"
        type="button"
        label=""
        severity="infos"
        icon="pi pi-arrow-right-arrow-left"
        @click="changeMeaning()"
      />

      <div class="inputs-row" style="padding: 10px 0; min-height: 140px">
        <Image
          :src="bottle"
          :alt="bottle"
          v-for="(bottle, n) in bottle.files_attachments"
          :key="n"
          width="123"
          height="123"
          class="mr-2"
          preview
        >
          <template #indicatoricon>
            <i class="pi pi-search"></i>
            <i class="pi pi-trash ml-3" @click.stop="deletePicture(n)"></i>
          </template>
        </Image>
        <p v-if="!bottle.files_attachments">No picture</p>
      </div>

      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row">
          <span class="p-float-label input-infos">
              <InputText
                id="title_norm"
                v-model="getSameTickersInfos.release_price"
              />
              <label for="title_norm">Release price</label>
            </span>
            <span class="p-float-label input-infos">
              <Calendar v-model="getSameTickersInfos.release_date" dateFormat="yy-mm-dd" id="calendar-release-date" showIcon />
              <label for="calendar-release-date">Release date (YYYY-MM-DD)</label>
            </span>
            <span class="p-float-label input-infos">
              <InputText
                id="title_norm"
                v-model="getSameTickersInfos.allowed_min_pictures"
              />
              <label for="title_norm">Min pictures allowed</label>
            </span>

            <Button
              @click="updateRealeasePicture()"
              label="Update"
              severity="warning"
              class="ml-3"
            />
        </div>
      </div>

      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row">
          <span class="p-float-label input-infos">
            <InputText
              id="title_norm"
              :disabled="!canEditTicker"
              v-model="bottle.title_norm"
            />
            <label for="title_norm">Title</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="isbn"
              :disabled="!canEditTicker"
              v-model="bottle.isbn"
            />
            <label for="isbn">ISBN</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ticker"
              :disabled="!canEditTicker"
              v-model="bottle.ticker"
            />
            <label for="ticker">Ticker</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ticker_composite"
              :disabled="!canEditTicker"
              v-model="bottle.ticker_composite"
            />
            <label for="ticker_composite">Ticker composite</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ref_ticker"
              :disabled="!canEditTicker"
              v-model="bottle.ref_ticker"
            />
            <label for="ref_ticker">Ref ticker (WB/RX)</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="distillery"
              :disabled="!canEditTicker"
              v-model="bottle.distillery"
            />
            <label for="distillery">Distillery</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="country"
              :disabled="!canEditTicker"
              v-model="bottle.country"
            />
            <label for="country">Country</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="region"
              :disabled="!canEditTicker"
              v-model="bottle.region"
            />
            <label for="region">Region</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="age"
              :disabled="!canEditTicker"
              v-model="bottle.age"
            />
            <label for="age">Age</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="base"
              :disabled="!canEditTicker"
              v-model="bottle.base"
            />
            <label for="base">Base</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="bottle_size"
              :disabled="!canEditTicker"
              v-model="bottle.bottle_size"
            />
            <label for="bottle_size">Size</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="bottler"
              :disabled="!canEditTicker"
              v-model="bottle.bottler"
            />
            <label for="bottler">Bottler</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="bottling_date"
              :disabled="!canEditTicker"
              v-model="bottle.bottling_date"
            />
            <label for="bottling_date">Bottling date</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="cask_number"
              :disabled="!canEditTicker"
              v-model="bottle.cask_number"
            />
            <label for="cask_number">Cask n°</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="mark"
              :disabled="!canEditTicker"
              v-model="bottle.mark"
            />
            <label for="mark">Mark</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="num_bottles_produced"
              :disabled="!canEditTicker"
              v-model="bottle.num_bottles_produced"
            />
            <label for="num_bottles_produced">N. prod. bottles</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="production_method"
              :disabled="!canEditTicker"
              v-model="bottle.production_method"
            />
            <label for="production_method">Production method</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="strength"
              :disabled="!canEditTicker"
              v-model="bottle.strength"
            />
            <label for="strength">ABV</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="vintage"
              :disabled="!canEditTicker"
              v-model="bottle.vintage"
            />
            <label for="vintage">Vintage</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ean_generic"
              :disabled="!canEditTicker"
              v-model="bottle.ean_generic"
            />
            <label for="ean_generic">EAN generic (Vlookup)</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ean_primary"
              :disabled="!canEditTicker"
              v-model="bottle.ean_primary"
            />
            <label for="ean_primary">EAN primary</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="ean_secondary"
              :disabled="!canEditTicker"
              v-model="bottle.ean_secondary"
            />
            <label for="ean_secondary">EAN secondary</label>
          </span>
          <Dropdown
            v-model="bottle.distillery_status"
            :disabled="!canEditTicker"
            :options="distilleryStatus"
            optionLabel="label"
            optionValue="value"
            placeholder="Distillery status"
            class="w-full md:w-14rem"
          />
          <span class="p-float-label input-infos">
            <InputText
              id="keywords"
              :disabled="!canEditTicker"
              v-model="bottle.keywords"
            />
            <label for="keywords">Keywords</label>
          </span>
        </div>
      </div>
      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row input-infos">
          <span class="p-float-label">
            <InputText
              id="sold_date"
              :disabled="!canEditBottle"
              v-model="bottle.sold_date"
            />
            <label for="sold_date">Sold date</label>
          </span>
          <span class="p-float-label input-infos">
            <InputText
              id="price"
              :disabled="!canEditBottle"
              v-model="bottle.price"
            />
            <label for="price">Price (brut)</label>
          </span>

          <Dropdown
            v-model="bottle.currency"
            :disabled="!canEditBottle"
            :options="currencyList"
            optionLabel="label"
            optionValue="value"
            placeholder="Currency"
            class="w-full md:w-14rem"
          />

          <span class="p-float-label input-infos">
            <InputText
              id="eur"
              :disabled="!canEditBottle"
              v-model="bottle.eur"
            />
            <label for="eur">Price (EUR)</label>
          </span>
        </div>
      </div>
      <div
        v-if="bottle"
        style="height: 80px"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row flex-column">
          <span class="input-infos">
            <label for="allow_vlookup"
              >Vlookup name ({{ bottle.origin_title }}):
            </label>
            <InputSwitch
              v-model="bottle.allow_vlookup"
              :disabled="!canEditBottle"
              :true-value="1"
              :false-value="0"
              inputId="allow_vlookup"
            />
          </span>
          <span class="input-infos">
            <label for="allow_vlookup_ean"
              >Vlookup EAN ({{ bottle.ean_generic || "N/A" }}):
            </label>
            <InputSwitch
              v-model="bottle.allow_vlookup_ean"
              :disabled="!canEditTicker"
              :true-value="1"
              :false-value="0"
              inputId="allow_vlookup_ean"
            />
          </span>
        </div>
      </div>
      <div
        style="height: 20%"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row">
          <div class="font-medium text-500 mb-3">
            Tickerized date: {{ convertDate(bottle.tickerized_date) || "N/A" }}
          </div>
          <div class="font-medium text-500 mb-3 ml-5">
            Tickerized by: {{ bottle.tickerized_by }}
          </div>
        </div>
      </div>

      <div
        style="height: 60px"
        class="border-2 border-dashed surface-border mb-2"
      >
        <div v-if="bottle" class="inputs-row">
          <Button
            @click="canEditBottle = true"
            v-if="!canEditBottle"
            label="Unlock edit bottle"
            severity="warning"
            class="mr-2"
            disabled
          />
          <Button
            @click="canEditBottle = false"
            v-else
            label="Cancel edit bottle"
            severity="infos"
            class="mr-2"
            disabled
          />

          <Button
            @click="canEditTicker = true"
            v-if="!canEditTicker"
            label="Unlock edit ticker"
            severity="warning"
            class="mr-2"
            disabled
          />
          <Button
            @click="canEditTicker = false"
            v-else
            label="Cancel edit ticker"
            severity="infos"
            class="mr-2"
            disabled
          />

          <Button
            @click="canDelete = true"
            v-if="!canDelete"
            label="Unlock delete bottle"
            severity="danger"
            class="mr-2"
          />
          <Button
            @click="canDelete = false"
            v-else
            label="Cancel delete bottle"
            severity="infos"
          />
        </div>
      </div>

      <template #footer>
        <!-- <Button
          :disabled="!canDelete"
          label="Miniature"
          severity="info"
          class="mr-2"
        />
        <Button
          :disabled="!canDelete"
          label="Set"
          severity="info"
          class="mr-2"
        />
        <Button
          :disabled="!canDelete"
          label="Pack"
          severity="info"
          class="mr-2"
        />
        <Button
          :disabled="!canDelete"
          label="Other"
          severity="info"
          class="mr-2"
        /> -->
        <Button
          v-if="!bottle.is_reference"
          v-tooltip="{
            value:
              '/!\\ Images must have a plain background and not be copyrighted',
            showDelay: 100,
            hideDelay: 300,
          }"
          type="button"
          label="It's reference"
          severity="warning"
          icon="pi pi-star-fill"
          @click="setReference(bottle.ticker, bottle.id)"
        />
        <a
          :href="bottle.url"
          target="_blank"
          rel="noopener noreferrer"
          class="p-button font-bold mr-2"
          >Open link</a
        >
        <Button
          @click="deleteBottle()"
          :disabled="!canDelete"
          label="Delete"
          severity="danger"
        />
        <Button
          @click="update('ticker')"
          :label="'Update ' + bottle.ticker"
          severity="warning"
          disabled
          />
          <!-- :disabled="!canEditTicker" -->
        <Button
          @click="update('bottle')"
          label="Update bottle"
          severity="warning"
          disabled
        />
        <!-- :disabled="!canEditBottle" -->
      </template>
    </Dialog>

    <Dialog v-model:visible="dialogUploadTEPPicture" modal header="Upload TEP files to for this reference" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div>
        <FileUpload name="files" @before-send="beforeUpload" withCredentials :url="returnEnv + 'api/normalized/ticker/' + $route.params.ticker +'/pictures'" @upload="onAdvancedUpload()" :multiple="true" accept="image/*" :maxFileSize="100000000">
            <template #empty>
                <p>Drag and drop files to here to upload.</p>
            </template>
        </FileUpload>
      </div>
    </Dialog>

    <Toast />
  </div>
  <div v-else class="m-center lg:px-9">
    <Image
        :src="bottle"
        :alt="bottle"
        v-for="(bottle, n) in getSameTickersInfos?.files_attachments"
        :key="n"
        width="123"
        height="123"
        class="mr-2"
        preview
      >
        <template #indicatoricon>
          <i class="pi pi-search"></i>
        </template>
      </Image>

      <p v-if="!getSameTickersInfos">No pictures found.</p>

      <Button @click="dialogUploadPictures()" type="button" icon="pi pi-upload" class="mb-2" />

    <p>No ticker found.</p>
    <button @click="$router.go(-1)">Back</button>
    
    <Dialog v-model:visible="dialogUploadTEPPicture" modal header="Upload TEP files to for this reference" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div>
          <FileUpload name="files" @before-send="beforeUpload" withCredentials :url="returnEnv + 'api/normalized/ticker/' + $route.params.ticker + '/pictures'" @upload="onAdvancedUpload()" :multiple="true" accept="image/*" :maxFileSize="100000000">
              <template #empty>
                  <p>Drag and drop files to here to upload.</p>
              </template>
          </FileUpload>
        </div>
      </Dialog>
  
      <Toast />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import { findIndex } from "lodash";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "tickerBottles",
  data() {
    return {
      dialog: false,
      bottle: null,
      dialogTitle: null,
      dialogUploadTEPPicture: false,
      activeIndex: 0,
      page: 0,
      displayCustom: 0,
      envVar: process.env.VUE_APP_BASE_URL,

      canEditBottle: false,
      canEditTicker: false,
      canDelete: false,

      currencyList: [
        { label: "EUR", value: "€" },
        { label: "GBP", value: "£" },
        { label: "USD", value: "$" },
        { label: "SGD", value: "SGD$" },
        { label: "NZ", value: "NZ$" },
        { label: "HK", value: "HK$" },
        { label: "CAD", value: "CAD" },
        { label: "AUD", value: "AUD" },
      ],
      distilleryStatus: [
        { label: "Open", value: 1 },
        { label: "Close", value: 0 },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    ...mapGetters({
      getSameTickers: "getSameTickers",
      getSameTickersInfos: "getSameTickersInfos",
    }),
    returnEnv() {
      return this.envVar;
    }
  },
  methods: {
    ...mapActions({
      updateNormalizedBottle: "updateNormalizedBottle",
      updateNormalizedTicker: "updateNormalizedTicker",
      deleteNormalizedBottle: "deleteNormalizedBottle",
      reference: "reference",
      updateFilesAttachments: "updateFilesAttachments",
      ticker: "ticker",
      updateReleaseTicker: 'updateReleaseTicker'
    }),
    async updateRealeasePicture() {
      await this.updateReleaseTicker(this.getSameTickersInfos).then(() => {
        this.dialog = false;
        this.$router.go(-1);
      })
    },
    seeBottleDetail(data) {
      this.bottle = data;
      this.dialogTitle = this.bottle.title_norm;
      this.dialog = true;

      const findIndexBottle = findIndex(this.getSameTickers, { id: data.id });
      if (findIndexBottle) {
        this.page = findIndexBottle;
      } else {
        this.page = 0;
      }
    },
    closeDialogNormalizedBottleDetail() {
      this.dialog = false;
      this.bottle = null;
    },
    convertDate(date) {
      return m(date).format("DD/MM/YYYY");
    },
    async update(type) {
      if (type === "ticker") {
        const ids = [];
        const urls = [];
        this.getSameTickers.forEach((bottle) => {
          ids.push(bottle.id);
          urls.push(bottle.url);
        });

        this.bottle.ids = ids;
        this.bottle.urls = urls;

        await this.updateNormalizedTicker(this.bottle).then(() => {
          this.canEditTicker = false;
        });
      } else {
        await this.updateNormalizedBottle(this.bottle).then(() => {
          this.canEditBottle = false;
        });
      }
    },
    async deleteBottle() {
      const payload = {
        id: this.bottle.id,
        ticker: this.bottle.ticker,
      };

      await this.deleteNormalizedBottle(payload).then(() => {
        this.canDelete = false;
        this.dialog = false;
        this.bottle = null;
      });
    },
    updatePage(page) {
      this.bottle = this.getSameTickers[page];
    },
    setReference(ticker, id) {
      const payload = {
        ticker: ticker,
        id: id,
      };
      this.reference(payload);
      this.dialog = false;
      this.$router.go(-1);
    },
    async deletePicture(n) {
      this.bottle.files_attachments.splice(n, 1);

      const payload = {
        isbn: this.getSameTickersInfos.isbn,
        id: this.bottle.id,
        files_attachments: this.bottle.files_attachments,
      };

      await this.updateFilesAttachments(payload)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `${this.getSameTickersInfos.isbn}`,
            detail: "Update OK",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: `${this.getSameTickersInfos.isbn}`,
            detail: "Error",
            life: 3000,
          });
        });
    },
    async changeMeaning() {
      const payload = {
        isbn: this.getSameTickersInfos.isbn,
        id: this.bottle.id,
        files_attachments: [
          this.bottle.files_attachments[1],
          this.bottle.files_attachments[0],
        ],
      };

      await this.updateFilesAttachments(payload)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `${this.getSameTickersInfos.isbn}`,
            detail: "Update OK",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: `${this.getSameTickersInfos.isbn}`,
            detail: "Error",
            life: 3000,
          });
        })
        .finally(async () => {
          this.dialog = false;
        });
    },
    dialogUploadPictures() {
      this.dialogUploadTEPPicture = true;
    },
    beforeUpload(request) {
        console.log(request);
        let tokenLS = localStorage.getItem('token');
        request.xhr.setRequestHeader('Authorization', `Bearer ${tokenLS}`);
        return request;
      },
      onAdvancedUpload() {
        this.ticker(this.$route.params.ticker);
      }
  },
};
</script>

<style lang="scss">
.m-center {
  margin: auto;
}

.inputs-row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .input-infos {
    margin: 5px;
  }
}
</style>
