<template>
    <div>
        <DataTable v-model:filters="filters" :value="getBottles" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty> No suppliers found. </template>

            <Column field="created_at" header="Created at" style="min-width: 2rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data) }}</span>
                </template>
            </Column>

            <Column field="isbn" header="ISBN" style="min-width: 2rem"></Column>

            <Column field="store_start" header="Stored at" style="min-width: 2rem">
                <template #body="{ data }">
                    <span>{{ storedDate(data.store_start) }}</span>
                </template>
            </Column>

            <Column field="store_end" header="Stored end" style="min-width: 2rem">
                <template #body="{ data }">
                    <span>{{ storedDate(data.store_end) }}</span>
                </template>
            </Column>

            <Column field="enabled" header="" dataType="boolean" style="max-width: 1rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                </template>
            </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button @click="updateBottleDialog(data)" type="button" icon="pi pi-angle-right" rounded />
                </template>
            </Column>
        </DataTable>

        <Dialog v-model:visible="dialogBottleUpdate" modal header="Update bottle" :style="{ width: '90rem' }">

            <InputSwitch v-model="currentBottleUpdate.enabled" :trueValue="1" :falseValue="0" class="mt-3 mb-3" />

            <div class="flex w-12">
                <div class="flex flex-column gap-2">
                    <label for="ticker">Ticker* <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                    <AutoComplete v-model="currentBottleUpdate.ticker" :invalid="!currentBottleUpdate.ticker" optionLabel="ticker" :suggestions="getSearchTickers" @complete="searchByTickers" placeholder="Search with ticker..." />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-3">
                    <label for="storage">Supplier*</label>
                    <Dropdown :invalid="!currentBottleUpdate.supplier_uuid" v-model="currentBottleUpdate.supplier_uuid" :options="getSearchSuppliers" optionLabel="name" placeholder="Select supplier" />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-3">
                    <label for="storage">Storage origin*</label>
                    <Dropdown :invalid="!currentBottleUpdate.storage_origin" v-model="currentBottleUpdate.storage_origin" :options="storageOrigin" optionLabel="name" placeholder="Select an origin" />
                </div>
            </div>

            <!-- <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2">
                    <label for="email">User* <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                    <AutoComplete :invalid="!currentBottleUpdate.user_uuid" v-model="currentBottleUpdate.user_uuid" optionLabel="email" :suggestions="getSearchUsers" @complete="searchByUsers" placeholder="Search with email" />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-2">
                    <label for="store_start">Storage at :</label>
                    <Calendar :invalid="!currentBottleUpdate.store_start" v-model="currentBottleUpdate.store_start" dateFormat="dd/mm/yy" placeholder="Storage start at..." />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-2">
                    <label for="store_end">Storage end :</label>
                    <Calendar v-model="currentBottleUpdate.store_end" dateFormat="dd/mm/yy" placeholder="Storage ending at..." />
                </div>
            </div> -->

            <!-- <div class="flex mt-3 w-12">
                <div class="flex flex-column gap-2 w-3">
                    <label for="order">Order</label>
                    <InputText id="order" v-model="currentBottleUpdate.order_uuid" :disabled='!currentBottleUpdate.user_uuid' placeholder="Order uuid" />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-3">
                    <label for="portfolio">Portfolio bottle</label>
                    <Dropdown v-model="currentBottleUpdate.portfolio_bottle_uuid" optionLabel="bottle_uuid" :disabled='!currentBottleUpdate.user_uuid || !currentBottleUpdate.ticker' :options="getSearchPortfolioBottles" placeholder="Select a portfolio bottle">
                        <template #option="slotProps">
                            <div class="flex items-center">
                                <div>{{ slotProps.option.price }} EUR at {{ convertDate(slotProps.option.sold_date) }} ({{ convertType(slotProps.option.buy_or_sell) }}) </div>
                            </div>
                        </template>
                    </Dropdown>
                </div>

                <div class="flex flex-column gap-2 ml-2 w-3">
                    <label for="invoice">Invoice</label>
                    <InputText id="invoice" v-model="currentBottleUpdate.invoice_uuid" :disabled='!currentBottleUpdate.user_uuid' placeholder="Invoice uuid" />
                </div>

                <div class="flex flex-column gap-2 ml-2 w-3">
                    <label for="bottle_normalized">Bottle (TEP normalized)</label>
                    <InputText id="bottle_normalized" v-model="currentBottleUpdate.bottle_uuid" :disabled='!currentBottleUpdate.user_uuid || !currentBottleUpdate.ticker' placeholder="Bottle uuid" />
                </div>
            </div> -->
            
            <div class="flex justify-content-end gap-2 mt-2">
                <Button type="button" label="Cancel" severity="secondary" @click="dialogBottleUpdate = false"></Button>
                <Button type="button" label="Update" @click="updBottle()"></Button>
            </div>
        </Dialog>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';

export default {
    name: 'storageBottlesList',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            dialogBottleUpdate: false,
            currentBottleUpdate: null,
            storageOrigin: [
                { name: 'TEP to Storage', code: 'TEP' },
                { name: 'Manual deposit', code: 'MD_TO_TEP' },
                { name: 'Package send to TEP', code: 'PACKAGE_TO_TEP' },
                { name: 'Other', code: 'OTHER' },
            ]
        };
    },
    watch: {
        dialogBottleUpdate() {
            if (!this.dialogBottleUpdate) {
                this.currentBottleUpdate = null;
            }
        }
    },
    mounted() {
        this.bottles();
        this.searchSuppliers();
    },
    computed: {
        ...mapGetters({
            getBottles: 'getBottles',
            getSearchUsers: 'getSearchUsers',
            getSearchTickers: 'getSearchTickers',
            getSearchPortfolioBottles: 'getSearchPortfolioBottles',
            getSearchSuppliers: 'getSearchSuppliers'
        }),
    },
    methods: {
        ...mapActions({
            bottles: 'bottles',
            searchTickers: 'searchTickers',
            searchSuppliers: 'searchSuppliers'
        }),
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY');
        },
        storedDate(data) {
            if (data) {
                return m(data).format('DD/MM/YYYY');
            }

            return '-';
        },
        searchByTickers(event) {
            const payload = {
                search: event.query
            }

            this.searchTickers(payload);
        },
        updateBottleDialog(data) {
            console.log(data);
            
            this.currentBottleUpdate = data;
            this.dialogBottleUpdate = true;
        },
        async updBottle() {
            // await this.updateSupplier(this.currentBottleUpdate).then(() => {
            //     this.dialogBottleUpdate = false;
            // })
        }
    },
};
</script>