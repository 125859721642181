<template>
    <div class="card lg:px-9">
        <div class="flex flex-column border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
            <label for="ip" class="mb-2">IP</label>
            <InputText id="ip" class="mb-1" v-model="ip" aria-describedby="ip-help" />
            <small id="ip-help">Enter IP (format: 21.120.210.100).</small>

            <FloatLabel class="mt-3 flex flex-column">
                <label>Description</label>
                <Textarea v-model="description" rows="5" cols="30" />
            </FloatLabel>

            <Button @click="ban()" class="mt-4" label="Accept" severity="success" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';

export default {
    name: 'actionsByIP',
    data() {
        return {
            ip: null,
            description: null
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
            banIP: 'banIP'
        }),
        convertDate(date) {
            if (date) {  
                return m(date).format('DD/MM/YYYY');
            }
            return '-';
        },
        async ban() {
            const payload = {
                ip: this.ip,
                description: this.description
            }

            await this.banIP(payload).then(() => {
                this.ip = null;
                this.description = null;
            })
        }
    },
    mounted() {}
};
</script>