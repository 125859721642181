<template>
    <div class="card lg:px-9">
        <div class="card">
            <TabMenu v-model:activeIndex="active" :model="items" />

            <div v-if="active === 0" class="flex flex-wrap justify-center align-items-center">
                <Card style="width: 35em" class="mx-2 my-2" v-for="error, n in getErrorsBottles" :key="n">
                    <template #title>{{ convertDate(error) }} - {{ error.isbn }}</template>
                    <template #subtitle>{{ errorsLabel(error.errors_bottle) }}</template>
                    <template #content>
                        <p class="m-0">
                            {{ error.description || 'Empty description' }}
                        </p>
                        <a v-if="error.link_attachments" :href="error.link_attachments" target="_blank" rel="noopener noreferrer" class="p-button mt-2">Check URL</a>
                        <p v-else>Empty URL.</p>

                        <div>
                            <p v-if="error.firstname"><strong>{{ error.firstname }} {{ error.lastname.toUpperCase() }}</strong></p>
                            <p v-else>Name not found.</p>
                        </div>

                        <div class="card flex">
                            <img :src="img" class="mr-2 my-2" v-for="img, i in error.pictures_attachments" :key="i" width="120">
                        </div>
                    </template>
                    <template #footer>
                        <InputText type="text" placeholder="Ticker" v-model="tickerResolve" class="mr-2" />
                        <Button :disabled="!tickerResolve" @click="sendVerdict(error.id, 0, 1, 0)" icon="pi pi-check" label="Resolve" severity="success" />
                        <Button @click="sendVerdict(error.id, 0, 0)" icon="pi pi-times" label="Delete" severity="danger" style="margin-left: 0.5em" />
                    </template>
                </Card>yyyyy
            </div>
            <div v-if="active === 1" class="flex flex-wrap justify-center align-items-center">
                <Card style="width: 35em" class="mx-2 my-2" v-for="missing, n in getMissingBottles" :key="n">
                    <template #title>{{ convertDate(missing) }}</template>
                    <template #subtitle>{{ missing.title }}</template>
                    <template #content>
                        <p class="m-0">
                            {{ missing.description || 'Empty description' }}
                        </p>
                        <a v-if="missing.link_attachments" :href="missing.link_attachments" target="_blank" rel="noopener noreferrer" class="p-button mt-2">Check URL</a>
                        <p v-else>Empty URL.</p>

                        <div>
                            <p v-if="missing.firstname"><strong>{{ missing.firstname }} {{ missing.lastname.toUpperCase() }}</strong></p>
                            <p v-else>Name not found.</p>
                        </div>
                    </template>
                    <template #footer>
                        <InputText type="text" placeholder="Ticker" v-model="tickerResolve" class="mr-2" />
                        <Button :disabled="!tickerResolve" @click="sendVerdict(missing.id, 0, 1, 1)" icon="pi pi-check" label="Resolve" severity="success" />
                        <Button @click="sendVerdict(missing.id, 0, 0)" icon="pi pi-times" label="Delete" severity="danger" style="margin-left: 0.5em" />
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { find } from 'lodash';
import m from 'moment';

export default {
    name: 'reportsBottles',
    data() {
        return {
            active: 0,
            items: [
                { label: 'Errors (product page)', icon: 'pi pi-exclamation-triangle' },
                { label: 'Missing (browser)', icon: 'pi pi-question' }
            ],
            errorList : [
                { label: 'Title', value: 0 },
                { label: 'Ticker', value: 1 },
                { label: 'Ticker composite', value: 2 },
                { label: 'ISBN', value: 3 },
                { label: 'Spirit type', value: 4 },
                { label: 'Distillery', value: 5 },
                { label: 'Country', value: 6 },
                { label: 'Region', value: 7 },
                { label: 'Bottler', value: 8 },
                { label: 'Age', value: 9 },
                { label: 'Abv', value: 10 },
                { label: 'Vintage', value: 11 },
                { label: 'Bottle size', value: 12 },
                { label: 'Total number bottles', value: 13 },
                { label: 'Production method', value: 14 },
                { label: 'Distillery status', value: 15 },
                { label: 'Pictures', value: 16 },
                { label: 'Other', value: 17 }
            ],
            tickerResolve: null
        };
    },
    computed: {
        ...mapGetters({
            getMissingBottles: 'getMissingBottles',
            getErrorsBottles: 'getErrorsBottles'
        }),
    },
    methods: {
        ...mapActions({
            verdict: 'verdict'
        }),
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY');
        },
        sendVerdict(id, enabled, is_approved, type) {
            const payload = {
                id: id,
                enabled: enabled,
                is_approved: is_approved,
                type: type,
                ticker: this.tickerResolve
            }

            this.verdict(payload).then(() => {
                this.tickerResolve = null;
            });
        },
        errorsLabel(errors) {
            let label = '';

            errors.forEach(element => {
                const findError = find(this.errorList, (o) => {
                    return o.value == element;
                });

                if (findError) {
                    label += findError.label + ', ';
                }
            });

            return label;
        }
    },
};
</script>