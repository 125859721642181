<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="mt-2">

            <DataTable v-model:filters="filters" :value="getBottlers" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </template>
                <template #empty> No bottler found. </template>

                <Column field="name" header="Name"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="phone" header="Phone"></Column>

                <Column field="sign_date" header="Sign date">
                    <template #body="{ data }">
                        {{ displayDate(data.sign_date) }}
                    </template>
                </Column>

                <Column field="renew_date" header="Renew date">
                    <template #body="{ data }">
                        {{ displayDate(data.renew_date) }}
                    </template>
                </Column>

                <Column field="created_at" header="End date">
                    <template #body="{ data }">
                        {{ displayDate(data.end_date) }}
                    </template>
                </Column>

                <Column field="files_attachments" header="Logo" dataType="boolean" style="max-width: 2rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.files_attachments.length > 0, 'pi-times-circle text-red-400': data.files_attachments.length === 0 }"></i>
                    </template>
                </Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Dialog v-model:visible="dialogUploadTEPPicture" modal header="Upload TEP files to for this bottler" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                            <div>
                                <FileUpload name="files" @before-send="beforeUpload" withCredentials :url="returnEnv + 'api/bottler/picture/' + + data.id" @upload="onAdvancedUpload()" accept="image/*" :maxFileSize="100000000">
                                    <template #empty>
                                        <p>Drag and drop files to here to upload. | dev info : {{ data.id }}</p>
                                    </template>
                                </FileUpload>
                            </div>
                        </Dialog>

                        <div class="flex gap-2">
                            <Button @click="dialogUploadTEPPicture = true" type="button" icon="pi pi-upload" rounded />
                            <Button @click="delBottler(data.id)" type="button" icon="pi pi-trash" rounded />
                            <Button @click="updateBottlerDialog(data)" type="button" icon="pi pi-pencil" rounded />
                        </div>
                    </template>
                </Column>
            </DataTable>

            <Dialog header="Bottler details" v-model:visible="dialogUpdateBottler" :modal="true" :closable="true" @hide="reinitSelectedBottler" class="dialogUpdate">
                <div v-if="currentUpdateBottler"  class="flex flex-column gap-2">
                
                    <div>
                        <div class="flex flex-column gap-2">
                            <label for="name">Name</label>
                            <InputText id="name" v-model="currentUpdateBottler.name" required/>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="flex flex-column gap-2">
                            <label for="email">Email</label>
                            <InputText id="email" v-model="currentUpdateBottler.email" required/>
                        </div>

                        <div class="flex flex-column gap-2 ml-2">
                            <label for="phone">Phone</label>
                            <InputText id="phone" v-model="currentUpdateBottler.phone" required/>
                        </div>

                        <div class="flex flex-column gap-2 ml-2">
                            <label for="end_date">Created :</label>
                            <Calendar v-model="currentUpdateBottler.created_at" dateFormat="dd/mm/yy" disabled/>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="flex flex-column gap-2">
                            <label for="sign_date">Sign date :</label>
                            <Calendar v-model="currentUpdateBottler.sign_date" dateFormat="dd/mm/yy" required/>
                        </div>

                        <div class="flex flex-column gap-2 ml-2">
                            <label for="renew_date">Renew date :</label>
                            <Calendar v-model="currentUpdateBottler.renew_date" dateFormat="dd/mm/yy" required/>
                        </div>

                        <div class="flex flex-column gap-2 ml-2">
                            <label for="end_date">End date :</label>
                            <Calendar v-model="currentUpdateBottler.end_date" dateFormat="dd/mm/yy" required/>
                        </div>
                    </div>

                    <Editor v-model="currentUpdateBottler.description" editorStyle="height: 320px" />

                    <!-- <div class="form-group">
                        <label for="image-upload">Add bottler logo (png) : </label>
                        <input type="file" id="image-upload" @change="onFileChange" accept="image/png, image/jpg, image/jpeg" />
                    </div> -->

                    <Button
                        @click="editBottler()"
                        label="Update bottler"
                        severity="success"
                        :disabled="!currentUpdateBottler.name || !currentUpdateBottler.description"
                    />
                </div>
            </Dialog>
        </div>

        <div v-if="active === 1" class="mt-2 flex flex-column gap-2">
            <div>
                <div class="flex flex-column gap-2">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="bottler.name" required/>
                </div>
            </div>

            <div class="flex">
                <div class="flex flex-column gap-2">
                    <label for="email">Email</label>
                    <InputText id="email" v-model="bottler.email" required/>
                </div>

                <div class="flex flex-column gap-2 ml-2">
                    <label for="phone">Phone</label>
                    <InputText id="phone" v-model="bottler.phone" required/>
                </div>
            </div>

            <div class="flex">
                <div class="flex gap-2">
                    <label for="sign_date" class="align-self-center">Sign date :</label>
                    <Calendar v-model="bottler.sign_date" dateFormat="dd/mm/yy" required/>
                </div>

                <div class="flex gap-2 ml-2">
                    <label for="renew_date" class="align-self-center">Renew date :</label>
                    <Calendar v-model="bottler.renew_date" dateFormat="dd/mm/yy" required/>
                </div>

                <div class="flex gap-2 ml-2">
                    <label for="end_date" class="align-self-center">End date :</label>
                    <Calendar v-model="bottler.end_date" dateFormat="dd/mm/yy" required/>
                </div>
            </div>

            <Editor v-model="bottler.description" editorStyle="height: 320px" />

            <!-- <div class="form-group">
                <label for="image-upload">Add bottler logo (png) : </label>
                <input type="file" id="image-upload" @change="onFileChange" accept="image/png, image/jpg, image/jpeg" />
            </div> -->

            <Button
                @click="createBottler()"
                label="Create bottler"
                severity="success"
                :disabled="!bottler.name || !bottler.description"
            />
        </div>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';
// import { api } from '@/services/interceptors';

export default {
    name: 'bottlerPage',
    data() {
        return {
            envVar: process.env.VUE_APP_BASE_URL,
            active: 0,
            items: [
                { label: 'Bottlers list', icon: 'pi pi-list' },
                { label: 'Add bottler', icon: 'pi pi-plus' }
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            bottler: {
                name: null,
                email: null,
                phone: null,
                description: null,
                sign_date: null,
                renew_date: null,
                end_date: null
            },
            dialogUploadTEPPicture: false,
            dialogUpdateBottler: false,
            currentUpdateBottler: null
        };
    },
    computed: {
        ...mapGetters({
            getBottlers: 'getBottlers'
        }),
        returnEnv() {
            return this.envVar;
        }
    },
    methods: {
        ...mapActions({
            bottlers: 'bottlers',
            addBottlers: 'addBottlers',
            updateBottler: 'updateBottler',
            deleteBottler: 'deleteBottler'
        }),
        displayDate(date) {
            return m(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        },
        updateBottlerDialog(data) {
            this.currentUpdateBottler = data;
            this.dialogUpdateBottler = true;
        },
        reinitSelectedBottler() {
            this.dialogUpdateBottler = false;
            this.currentUpdateBottler = null;
        },
        onFileChange(e) {
            console.log(e);
        },
        async editBottler() {
            await this.updateBottler(this.currentUpdateBottler).then(() => {

                this.dialogUpdateBottler = false;
                this.currentUpdateBottler = null;

                this.$toast.add({ severity: 'success', summary: `Bottler updated`, detail: 'OK', life: 3000 });
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: `Fail to update bottler`, detail: 'ERROR', life: 3000 });
            })
        },
        async createBottler() {
            await this.addBottlers(this.bottler).then(() => {
                this.$toast.add({ severity: 'success', summary: `Bottler created`, detail: 'OK', life: 3000 });

                this.bottler = {
                name: null,
                description: null,
                sign_date: null,
                renew_date: null,
                end_date: null
            }

            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: `Fail to created bottler`, detail: 'ERROR', life: 3000 });
            })
        },
        async delBottler(id) {
            await this.deleteBottler(id).then(() => {
                this.$toast.add({ severity: 'success', summary: `Bottler deleted`, detail: 'OK', life: 3000 });
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: `Fail to delete bottler`, detail: 'ERROR', life: 3000 });
            })
        },
        beforeUpload(request) {
            console.log(request);
            let tokenLS = localStorage.getItem('token');
            request.xhr.setRequestHeader('Authorization', `Bearer ${tokenLS}`);
            return request;
        },
        onAdvancedUpload() {
            this.bottlers();
            this.dialogUploadTEPPicture = false;
        }
    },
    mounted() {
        this.bottlers();
    }
};
</script>

<style>
</style>