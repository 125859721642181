import { api } from '@/services/interceptors';

export default {
  state: {
    bottles: [],
    currentBottle: null,
    suppliers: [],
    country: []
  },
  getters: {
    getBottles(state) {
        return state.bottles;
    },
    getBottle(state) {
        return state.currentBottle;
    },
    getSuppliers(state) {
        return state.suppliers;
    },
    getCountryStorage(state) {
        return state.country;
    },
  },
  actions: {
    bottles({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/bottles').then((res) => {
                commit('STORAGE_BOTTLES', res.data.bottles);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    country({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/country').then((res) => {
                commit('STORAGE_COUNTRY', res.data.country);                
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    updateCountry({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            api.put('api/storage/country', payload).then(() => {
                commit('STORE_NOTHING');
                dispatch('country');
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    suppliers({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/suppliers').then((res) => {
                commit('STORAGE_SUPPLIERS', res.data.suppliers);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    addSupplier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/storage/supplier', payload).then(() => {
                commit('STORE_NOTHING');
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    updateSupplier({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            api.put('api/storage/supplier', payload).then(() => {
                commit('STORE_NOTHING');
                dispatch('suppliers');
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    bottle({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/bottle/' + payload).then((res) => {
                commit('STORAGE_BOTTLE', res.data.bottle);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    addBottleToStorage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/storage/bottle', payload).then(() => {
                commit('STORE_NOTHING');
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
  },
  mutations: {
    STORAGE_BOTTLES(currentState, bottles) {
        currentState.bottles = bottles;
    },
    STORAGE_SUPPLIERS(currentState, suppliers) {
        currentState.suppliers = suppliers;
    },
    STORAGE_BOTTLE(currentState, currentBottle) {
        currentState.currentBottle = currentBottle;
    },
    STORAGE_COUNTRY(currentState, country) {        
        currentState.country = country;
    },
    STORE_NOTHING() { }
  }
};