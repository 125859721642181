<template>
    <div>
        <DataTable v-model:filters="filters" :value="getCountryStorage" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty> No country found. </template>

            <Column field="alpha2" header="" style="min-width: 1rem">
                <template #body="{ data }">
                    <span>{{ data.alpha2 }}</span>
                </template>
            </Column>

            <Column field="nom_en_gb" header="Name"></Column>

            <Column field="enabled" header="Enabled" style="min-width: 2rem">
                <template #body="{ data }">
                    <InputSwitch @change="updCountry(data)" v-model="data.enabled" :trueValue="1" :falseValue="0" class="mt-3" />
                </template>
            </Column>
        </DataTable>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'storageAllowedCountry',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        };
    },
    watch: {},
    mounted() {
        this.country();        
    },
    computed: {
        ...mapGetters({
            getCountryStorage: 'getCountryStorage'
        }),
    },
    methods: {
        ...mapActions({
            country: 'country',
            updateCountry: 'updateCountry'
        }),
        async updCountry(data) {
            await this.updateCountry(data).then(() => {
                this.$toast.add({ severity: 'success', summary: ``, detail: 'Country updated', life: 3000 });
            }).catch(() => {
                this.$toast.add({ severity: 'danger', summary: ``, detail: 'Country update failed', life: 3000 });
            })
        }
    },
};
</script>