<template>
  <div class="card lg:px-9">
    <DataTable
      :value="changes"
      stripedRows
      paginator
      :rows="50"
      :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]"
      class="mt-2"
      tableStyle="min-width: 50rem"
    >
      <Column field="created_at" header="Date" style="max-width: 2rem"></Column>
      <Column field="type" header="Category" style="max-width: 3rem"></Column>
      <Column
        field="description"
        header="Description"
        style="max-width: 8rem"
      ></Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  name: "changeLogs",
  data() {
    return {
      changes: [
        {
          created_at: "21/05/2024",
          type: "Multi",
          description:
            "Picture indicator on bottle line (tickerized), change picture meaning, delete picture, is reference change mapping",
        },
        {
          created_at: "22/04/2024",
          type: "Multi",
          description:
            "Normalized: nombre de bouteilles disponibles sur le tableau et sauvegarde de la recherche. Ajout d'un affichage si pas de bouteille en backend avec un bouton retour. Gestion d'un bug qui générait un pending infini. Changement de la valeur par défaut du tableau tickers de 10 à 100.",
        },
        {
          created_at: "au 31/03/2024",
          type: "Normalized",
          description:
            "Résolution d'un bug qui ne premettait plus de modifier une référence entière. Suppression des tickers disponible seulement si celui-ci ne contient aucune bouteille normalisée.",
        },
        {
          created_at: "-",
          type: "Historique",
          description:
            "Aperçu des actions backoffice effectuées sur les dernières 24 heures. Vous avez possibilité de cliquer sur la ligne si celle-ci est liée à un utilisateur + voir les détails techniques.",
        },
        {
          created_at: "-",
          type: "Scraping",
          description:
            "Vous pouvez télécharger toutes les bouteilles non normalisées avec un ou plusieurs mots clefs.",
        },
        {
          created_at: "-",
          type: "Global",
          description:
            "Historique des recherches et produits indiquent maintenant le nom et prénom (au lieu d'un UUID). Ajout du ban IP. Le dropdown pour choisir la devise et le statut d'ouverture d'une distillerie sur une bouteille fonctionne. Ajout d'une colonne sur la ban liste pour identifier la source du bannissement. Ajout d'une historisation des actions bakoffice plus poussée.",
        },
        {
          created_at: "-",
          type: "Emergencies",
          description:
            "Vous pouvez activer/désactiver des fonctionnalités - A utiliser en cas d'alerte de sécurité ou d'un bug impactant fortement l'application. Le 2FA peut être géré en mode email/téléphone ou désactivé (à utiliser en cas d'extrême urgence !)",
        },
        {
          created_at: "du 28/03/2024",
          type: "Orders",
          description:
            "Prévisualisation simple des ordres pour chaque utilisateur + liste des ordres globaux. Vous pouvez accepter ou décliner les ordres (avec raisons)",
        },
        {
          created_at: "09/03/2024",
          type: "User",
          description:
            "Ban ip (prod website), ajout de la visualisation des devices dans le détails des utilisateurs > history",
        },
        {
          created_at: "09/02/2024",
          type: "Users",
          description:
            "Visualisation du nombre/type d'abonnement dans le tableau des utilisateurs. Passage à 50 la liste par défaut pour l'historique produit et recherche",
        },
        {
          created_at: "05/02/2024",
          type: "User",
          description:
            "Vous pouvez maintenant supprimer des utilisateurs. Attention pas de retour en arrière possible. Disponible depuis les actions sur la fiche utilisateur.",
        },
        {
          created_at: "28/01/2024",
          type: "User",
          description:
            "Vous pouvez confirmer/annuler la vérification de l'email et du téléphone. Vous pouvez voir les factures liées à un utilisateur et voir le PDF. Les abonnements sont maintenants visibles pour chaque utilisateur.",
        },
        {
          created_at: "27/01/2024",
          type: "Normalized",
          description:
            "Vous pouvez ajouter des bouteilles directrices pour chaque références. Cette option sert à choisir les bonnes images et éviter les coquilles côté frontend. Améliorations UI/UX.",
        },
      ],
    };
  },
};
</script>
