import { api } from '@/services/interceptors';

export default {
  state: {
    bottlers: []
  },
  getters: {
    getBottlers(state) {
        return state.bottlers;
    },
  },
  actions: {
    bottlers({ commit }) {
        api.get('api/bottlers').then((res) => {
            commit('STORAGE_BOTTLERS', res.data.bottlers);
        });
    },
    addBottlers({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('STORE_NOTHING');
            api.post('api/bottler', payload).then(() => {
                resolve();
                dispatch('bottlers');
            }).catch(() => {
                reject();
            })
        })
    },
    updateBottler({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('STORE_NOTHING');
            api.put(`api/bottler/${payload.id}`, payload).then(() => {
                resolve();
                dispatch('bottlers');
            }).catch(() => {
                reject();
            })
        })
    },
    deleteBottler({ commit, dispatch }, bottlerId) {
        return new Promise((resolve, reject) => {
            commit('STORE_NOTHING');
            api.delete(`api/bottler/${bottlerId}`).then(() => {
                resolve();
              dispatch('bottlers');
            }).catch(() => {
                reject();
            })
        })
    }
  },
  mutations: {
    STORAGE_BOTTLERS(currentState, bottlers) {
        currentState.bottlers = bottlers;
    },
    STORE_NOTHING() { }
  }
};