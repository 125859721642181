import { api } from '@/services/interceptors';

export default {
  state: {
    articles: [],
    tags: []
  },
  getters: {
    getArticles(state) {
        return state.articles;
    },
    getTags(state) {
      return state.tags;
    }
  },
  actions: {
    articles({ commit }) {
        api.get('api/articles').then((res) => {
            commit('STORAGE_articles', res.data.articles);
        });
    },
    tags({ commit }) {
        api.get('api/tags').then((res) => {
            commit('STORAGE_tags', res.data.tags);
        });
    },
    addArticle({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        api.post('api/articles', payload).then(() => {
            dispatch('articles');
        });
    },
    addTag({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        api.post('api/tags', payload).then(() => {
            dispatch('tags');
        });
    },
    updateArticle({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      console.log(payload.id);
      api.put(`api/articles/${payload.id}`, payload).then(() => {
        dispatch('articles');
      });
    },
    deleteArticle({ commit, dispatch }, articleId) {
      commit('STORE_NOTHING');
      api.delete(`api/articles/${articleId}`).then(() => {
        dispatch('articles');
      });
    }
  },
  mutations: {
    STORAGE_articles(currentState, articles) {
        currentState.articles = articles;
    },
    STORAGE_tags(currentState, tags) {
        currentState.tags = tags;
    },
    STORE_NOTHING() { }
  }
};