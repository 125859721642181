import { api } from '@/services/interceptors';

export default {
  state: {
    users: [],
    tickers: [],
    suppliers: [],
    portfolioBottles: [],
    country: []
  },
  getters: {
    getSearchUsers(state) {
        return state.users;
    },
    getSearchTickers(state) {
        return state.tickers;
    },
    getSearchSuppliers(state) {
        return state.suppliers;
    },
    getSearchPortfolioBottles(state) {
        return state.portfolioBottles;
    },
    getCountry(state) {
        return state.country;
    },
  },
  actions: {
    searchUsers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/search/users', payload).then((res) => {
                commit('STORAGE_SEARCH_USERS', res.data.results);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    searchTickers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/search/mapping', payload).then((res) => {
                commit('STORAGE_SEARCH_TICKERS', res.data.results);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    searchSuppliers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/search/suppliers', payload).then((res) => {
                commit('STORAGE_SEARCH_SUPPLIERS', res.data.results);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    searchPortfolioBottles({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/search/portfolio/bottles', payload).then((res) => {
                commit('STORAGE_SEARCH_PORTFOLIO_BOTTLES', res.data.results);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    countryList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.get('api/lists/country', payload).then((res) => {
                commit('STORE_COUNTRY', res.data.results);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    }
  },
  mutations: {
    STORAGE_SEARCH_USERS(currentState, users) {
        currentState.users = users;
    },
    STORAGE_SEARCH_TICKERS(currentState, tickers) {
        currentState.tickers = tickers;
    },
    STORAGE_SEARCH_SUPPLIERS(currentState, suppliers) {
        currentState.suppliers = suppliers;
    },
    STORAGE_SEARCH_PORTFOLIO_BOTTLES(currentState, portfolioBottles) {
        currentState.portfolioBottles = portfolioBottles;
    },
    STORE_COUNTRY(currentState, country) {
        currentState.country = country;
    },
    STORE_NOTHING() { }
  }
};