<template>
    <div class="card lg:px-9 mt-3">
        <div class="card">
            <h4>Top search products</h4>
            <DataTable v-if="getUsersStatistics" :value="getUsersStatistics.isbn" stripedRows paginator :rows="5" :rowsPerPageOptions="[5, 10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <Column field="isbn" header="ISBN" style="max-width: 2rem"></Column>
                <Column field="count" header="Count" style="max-width: 1rem"></Column>

                <!-- <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <a :href="data.view_url" target="_blank" rel="noopener noreferrer">
                            <Button type="button" icon="pi pi-file-pdf" rounded />
                        </a>
                    </template>
                </Column> -->
            </DataTable>
        </div>
    </div>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'dashBoard',
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            getUsersStatistics: 'getUsersStatistics'
        }),
    },
    methods: {
        ...mapActions({
            userStatistics: 'userStatistics'
        }),
    },
};
</script>

<style>
    .m-center {
        margin: auto;
    }
</style>