import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/mdc-dark-indigo/theme.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css'
/* Import components from PrimeVue */
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DataView from 'primevue/dataview';
import Menubar from 'primevue/menubar';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Galleria from 'primevue/galleria';
import Badge from 'primevue/badge';
import TabMenu from 'primevue/tabmenu';
import Card from 'primevue/card';
import ProgressBar from 'primevue/progressbar';
import Tag from 'primevue/tag';
import Carousel from 'primevue/carousel';
import Image from 'primevue/image';
import Tooltip from 'primevue/tooltip';
import SelectButton from 'primevue/selectbutton';
import MultiSelect from 'primevue/multiselect';
import ToggleButton from 'primevue/togglebutton';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import Calendar from 'primevue/calendar';
import Avatar from 'primevue/avatar';
import AutoComplete from 'primevue/autocomplete';
import AvatarGroup from 'primevue/avatargroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Toast from 'primevue/toast';
import AnimateOnScroll from 'primevue/animateonscroll';
import SpeedDial from 'primevue/speeddial';
import Chart from 'primevue/chart';
import Message from 'primevue/message';

createApp(App)
.use(store)
.use(router)
.use(PrimeVue, { ripple: true  })
.use(ConfirmationService)
.use(ToastService)
.directive('animateonscroll', AnimateOnScroll)
.directive('tooltip', Tooltip)
.component('InputText', InputText)
.component('Button', Button)
.component('Checkbox', Checkbox)
.component('DataTable', DataTable)
.component('DataView', DataView)
.component('Column', Column)
.component('Menubar', Menubar)
.component('Dialog', Dialog)
.component('InputSwitch', InputSwitch)
.component('Dropdown', Dropdown)
.component('Textarea', Textarea)
.component('Galleria', Galleria)
.component('Badge', Badge)
.component('TabMenu', TabMenu)
.component('Card', Card)
.component('ProgressBar', ProgressBar)
.component('Tag', Tag)
.component('Carousel', Carousel)
.component('Image', Image)
.component('Tooltip', Tooltip)
.component('SelectButton', SelectButton)
.component('MultiSelect', MultiSelect)
.component('AutoComplete', AutoComplete)
.component('ToggleButton', ToggleButton)
.component('Editor', Editor)
.component('FileUpload', FileUpload)
.component('Calendar', Calendar)
.component('Avatar', Avatar)
.component('AvatarGroup', AvatarGroup)
.component('ConfirmDialog', ConfirmDialog)
.component('ConfirmPopup', ConfirmPopup)
.component('Toast', Toast)
.component('SpeedDial', SpeedDial)
.component('FileUpload', FileUpload)
.component('Chart', Chart)
.component('Calendar', Calendar)
.component('Message', Message)
.mount('#app')