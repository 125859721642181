<template>
    <div class="card lg:px-9">
        <div class="card">
            <DataTable v-model:filters="filters" :value="getBanIP" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                        </span>
                    </div>
                </template>
                <template #empty> Not found. </template>

                <Column field="created_at" header="First attack" sortable>
                    <template #body="{ data }">
                        <span>{{ convertDate(data.created_at) }}</span>
                    </template>
                </Column>

                <Column field="reason" header="Reason" sortable></Column>

                <Column field="created_by" header="... by" sortable></Column>

                <Column field="updated_at" header="Last attack" sortable>
                    <template #body="{ data }">
                        <span>{{ convertDate(data.updated_at) }}</span>
                    </template>
                </Column>
                <Column field="ip" header="IP">
                    <template #body="{ data }">
                        <span>{{ ipConvert(data) }}</span>
                    </template>
                </Column>
                <Column field="country" header="Country">
                    <template #body="{ data }">
                        <span>{{ data.country || '-' }}</span>
                    </template>
                </Column>
                <Column field="count" header="Count" sortable></Column>
                <Column field="enabled" header="Ban ?" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                </template>
            </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'historyBanIP',
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    computed: {
        ...mapGetters({
            getBanIP: 'getBanIP',
        }),
    },
    methods: {
        ...mapActions({
            historyBanIP: 'historyBanIP',
        }),
        convertDate(data) {
            if (data) {
                return m(data).format('DD/MM/YYYY HH:mm:ss');
            }
            return '-';
        },
        ipConvert(data) {
            return data.ip.replace('::ffff:', '');
        }
    },
    mounted() {
        this.historyBanIP();
    }
};
</script>